import React, { useEffect, useState } from 'react'
import domainName from '../../domainName'
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme'
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


const MyRequest = () => {
    const { token, UserData, UserId } = useSelector((state) => state.login);

    const [t] = useTranslation("global");

    const [displayListing, setDisplayListing] = useState([])

    //set the item which user want to delete
    const [wantDeleteItem,setWantDeleteItem] = useState()

    //for delete request modal
    const [showDeleteRequest,setShowDeleteRequest] = useState(false)

    async function deleteRequest() {
     
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/deleteUserRequest",
                "POST",
                JSON.stringify({
                    "UserId": wantDeleteItem.PosterUserId,
                    "RequestId": wantDeleteItem._id,
                    "DeleteInfo": "test"
                }),
                // undefined, // user id, wait binglun settle backend
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log("responseJson /user_request/deleteUserRequest ->", responseJson);
                setShowDeleteRequest(false)
                window.location.reload();
            }))
            .catch((error) => {
                // console.log("error /user_request/deleteUserRequest ->", error);
            });
    }

    async function getRequestListing() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/selectUserRequestListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "PageNum": 1,
                    "ResultNum": 100
                }),
                // undefined, // user id, wait binglun settle backend
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                // console.log("responseJson /user_request/exlporeUserRequestListing ->", responseJson);
                setDisplayListing(responseJson.Data)
            }))
            .catch((error) => {
                // console.log("error /user_request/exlporeUserRequestListing ->", error);
            });
    }

    function DeleteRequestModal() {
        return (
            <Modal show={showDeleteRequest} onHide={() => setShowDeleteRequest(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('myRequest.Delete Request')} {wantDeleteItem?.RequestIdNo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('myRequest.Are you sure you want to delete this request?')}</Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteRequest(false)}>
                    {t('myRequest.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={() => {
                        deleteRequest()
                    }}>
                        {t('myRequest.Delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function renderNoBookings() {
        return (
            <Row>
                <h4 style={{ textAlign: 'center' }}>{t("myRequest.You don't have any request yet")}</h4>
            </Row>
        )
    }

    function renderRequestListings(item) {
        return (
            <div style={{ marginTop: '2%', marginBottom: '1%', padding: '1%', borderWidth: 0, borderBottomWidth: 1.5, borderColor: COLORS.lightGray1, borderStyle: 'solid', }}>
                <Row className="my-3">
                    <Col><h4>{t('myRequest.Request No')} : {item.RequestIdNo}</h4></Col>
                    <Col className="d-flex justify-content-end">{item.DisplayDateTime}</Col>
                </Row>
                <Row className="my-3">
                    <Col> <img src={require('../../img/icons/categories.png')} style={{ width: 35, height: 35 }} /> {item.CategoryDisplayList[0].CategoryName} :{item.CategoryDisplayList[0].SubCateogrynameDisplay}</Col>
                </Row>
                <Row className="my-3">
                    <Col> <img src={require('../../img/icons/location.png')} style={{ width: 35, height: 35 }} /> {item.DisplayLocation}</Col>
                </Row>
                <Row className="my-3">
                    <Col> <img src={require('../../img/icons/description.png')} style={{ width: 35, height: 35 }} />  {item.ExtraInfo}</Col>
                </Row>
                <Row>
                    <button
                        className="btn-white text-dark  fw-bold btn"
                        style={{ borderColor: COLORS.gray }}
                        onClick={() => {
                            setWantDeleteItem(item)
                            setShowDeleteRequest(true) 
                        }}
                    >{t('myRequest.Delete')}</button>
                </Row>
            </div>
        )
    }

    useEffect(() => {

        getRequestListing()

    }, [])

    return (
        <div style={{ backgroundColor: "#F8F8F8", padding: '2%' }}>
            <Container className="width95" style={{ backgroundColor: COLORS.white, paddingBottom: '2%' }}>
                <h1 style={{ marginBottom: '3%' }}>{t('myRequest.My Requests')}</h1>

                {displayListing == "" ? <div style={{ marginTop: '3%' }}>{renderNoBookings()}</div>
                    :
                    displayListing.map((item, index) => (
                        renderRequestListings(item)
                    ))}

                    <DeleteRequestModal />
            </Container>
        </div>
    )
}

export default MyRequest