import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import domainName from '../../domainName';
import { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../colorTheme/colorTheme';
import { SHA256 } from 'crypto-js';
import { logOut } from '../../redux/slices/login';
import { insertAnonymousUserId } from '../../redux/slices/login';
import { useTranslation } from "react-i18next";


const EnterVerificationCode = () => {

    const [t] = useTranslation("global");

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const smsKey = process.env.REACT_APP_SMS_SECRET_KEY;
    const apiKey = process.env.REACT_APP_API_KEY;

    const { token, UserData, UserId } = useSelector((state) => state.login);

    //for timer 
    const [timeLeft, setTimeLeft] = useState(60);
    const [resendCodeVisible, setResendCodeVisible] = useState(false)


    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);

    const [phoneNumber, setPhoneNumber] = useState();
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [referralCode, setReferralCode] = useState();

    const handleChange = (e, index) => {
        const { value } = e.target;

        setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value;
            return newOtp;
        });

        // Proceed to next input box
        if (value !== '' && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    async function insertAnonymouseUser() {

        //do insert anonymous hereeee
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/insertAnonymouseUser",
                "POST",
                // JSON.stringify({
                // })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log('responseJson in /user/insertAnonymouseUser', responseJson);

                //here to do dispatch(AutoLogin .. something)
                if (responseJson.Data != null) {
                    dispatch(logOut());
                    dispatch(insertAnonymousUserId(responseJson.Data._id))
                } else {
                    alert(responseJson.ReturnDesc);
                }
                // anonymous still remain true

            }))
            .catch((error) => {
                alert(t("error.An error occurred. Please try again later."));
            });
    }


    const insertUser = async (loginType, password) => {
        const diversifyKey = Math.random().toString(36).substring(2, 12);

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateAnonymouseUserToUserWithEmailAndMobileNo",
                "POST",
                JSON.stringify({
                    "Email": null,
                    "MobileNo": phoneNumber,
                    "LoginType": loginType,
                    "Password": password,
                    "DiversifyKey": diversifyKey,
                    "UserName": userName,
                    "FirstRegisterReferralCode": referralCode ? referralCode : null
                }),
                UserId // this one will keep unless user login and logout, so we need to clear the cache at below (perform logout action)
            )
            .then(domainName.handleServerAPICallErrors)
            .then((async responseJson => {

                //console.log(responseJson);
                if (responseJson.ReturnCode == 200) { // returnCode 400 mean user existing

                    await insertAnonymouseUser()
                    navigate('/signupsuccess')
                } else {
                    if (responseJson.ReturnDesc) {
                        alert(responseJson.ReturnDesc)
                    } else {
                        alert(t("error.Something went wrong. Please contact your technical staff."))
                    }
                }

            }))
            .catch((error) => {
                //console.log("/user/updateAnonymouseUserToUserWithEmailAndMobileNo error:", error)
            });

    }


    async function insertUserWithPhoneNumber() {
        const otpValue = otp.join('');
        //console.log('OTP:', otpValue);
        //console.log('otpValue in verification code page', otpValue)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/verifyVerificationTwilioOtp",
                "POST",
                JSON.stringify({
                    "MobileNo": phoneNumber,
                    "OTP": otpValue
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((async responseJson => {
                //console.log('/user/verifyVerificationTwilioOtp responseJson here', responseJson)

                if (responseJson.ReturnCode == 200) {

                    //console.log(' insert user lor ~')

                    if (password != undefined) {
                        await insertUser('MOBILE_NO', password)
                    } else {
                        //console.log("from forgot password")

                        let randomId = (Math.random() + 1).toString(36).substring(2); // just random string
                        let currentTime = new Date().getTime(); //epoch timestamp now
                        let phoneHash = SHA256(phoneNumber);
                        let resetId = SHA256(phoneHash + currentTime + randomId)
                        // from forgot password
                        //navigate reset password
                        navigate(`/resetpassword?${queryString.stringify({
                            phoneNumber: phoneNumber,
                            tid: currentTime,
                            id: randomId,
                            resetid: resetId
                        })}`)
                    }


                    // if (passwordParam != undefined) {

                    //     const returnData = await insertUser('MOBILE_NO', passwordParam)
                    //     // ////console.log('returnData = await insertUser',returnData)
                    //     if (returnData.ReturnCode == 200) { // returnCode 400 mean user existing
                    //         navigation.navigate('SuccessSignUp', {})
                    //     } else {
                    //         Alert('Something went wrong, phone not registered with our system database, please ask for support')
                    //     }

                    // } else {

                    //     navigation.navigate('ResetPassword', {
                    //         phoneNumber: phoneNumber
                    //     })

                    // }
                } else {
                    alert(`${t("error.Oops!,")}${responseJson.ReturnDesc}`);
                }

            }))
            .catch((error) => {
                ////console.log('/user/verifyVerificationTwilioOtp error here', error)
            });
    }

    async function resendOTP() {

        ////console.log(`  "MobileNo": phoneNumber,`, phoneNumber)
        const smsid = SHA256(phoneNumber + "Adviser2u" + apiKey + smsKey)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/sendTwilioOtp",
                "POST",
                JSON.stringify({
                    "MobileNo": phoneNumber,
                    "SMS_ID": smsid.toString()
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/sendTwilioOtp responseJson here', responseJson)
                if (responseJson.ReturnCode == 200) {
                    setResendCodeVisible(false)
                    setOtp(['', '', '', '', '', ''])
                    setTimeLeft(60)
                    alert(t("error.OTP re-sent! Please enter again."));
                } else {
                    alert(t('error.Send otp got error, may contact administrator'))
                }
            }))
            .catch((error) => {
                //console.log('/user/sendTwilioOtp error here', error)
            });

    }



    useEffect(() => {
        let queryParams = queryString.parse(location.search);

        setPhoneNumber(queryParams.phoneNumber)
        setUserName(queryParams.username)
        setPassword(queryParams.password)
        setReferralCode(queryParams.referalcode)


    }, []);

    // for timer 
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
        }, 1000);

        if (timeLeft === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [timeLeft]);

    useEffect(() => {
        if (timeLeft === 0) {
            // Perform any action after the countdown is complete
            // set resend code become visible
            setResendCodeVisible(true)
        }
    }, [timeLeft]);

    // const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;


    const isOtpFilled = otp.every((digit) => digit !== '');

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h2 className="fw-bold">{t('enterVerificationCode.Enter Verification Code')}</h2>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <span style={{ textAlign: 'center' }}>{t('enterVerificationCode.Please type the verification code sent to')} <h3>{phoneNumber}</h3></span>
                            </Row>
                            <Row className="mt-4">
                                {otp.map((digit, index) => (
                                    <Col xs={2} md={2} key={index}>
                                        <input
                                            ref={(ref) => (inputRefs.current[index] = ref)}
                                            type="text"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleChange(e, index)}
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                fontSize: '20px',
                                                textAlign: 'center',
                                                border: '1px solid gray',
                                                borderRadius: '4px',
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && index === otp.length - 1) {
                                                    insertUserWithPhoneNumber();
                                                }
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Row className='mt-4'>
                                <Button disabled={!isOtpFilled} onClick={insertUserWithPhoneNumber}>{t('enterVerificationCode.next')}</Button>
                            </Row>

                            <Row className="justify-content-center mt-4">
                                <Col className="d-flex justify-content-center">
                                    <div>
                                        <span>{t('enterVerificationCode.Please wait')} <span style={{ color: COLORS.orange }}>{seconds}</span> {t('enterVerificationCode.seconds to resend.')}</span>
                                        <div onClick={() => { resendOTP() }}>
                                            <Link style={{ textDecorationLine: 'none' }}>
                                                <span style={{ color: COLORS.orange }}>{resendCodeVisible ? `Resend Code` : ''}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default EnterVerificationCode