import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image, Button, Card, Form } from 'react-bootstrap';
import planIcon from '../../img/valid.svg';
import axios from 'axios';
import domainName from '../../domainName';
import MessageUserCard from '../../components/MessageUserCard';
import MessageConversationCard from '../../components/MessageConversationCard';
import queryString from 'query-string';
import { useNavigate, useLocation } from "react-router-dom";
import './chatstyle.css';
import { COLORS } from '../../colorTheme/colorTheme';
import backIcon from '../../img/icons/back.png';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

import loadingGif from '../../img/loading.gif';

const Chat = ({ passInUserId, passInMessageId, onesignalId, setShowTabNavigation }) => {
    const messageListRef = useRef(null);

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [view, setView] = useState('');
    const [conversationList, setConversationList] = useState([])
    const [messageList, setMessageList] = useState([])
    const [messageCount, setMessageCount] = useState(0)
    const [lastMessageCount, setLastMessageCount] = useState(0);
    const [targetUser, setTargetUser] = useState({})
    const [messageInput, setMessageInput] = useState('');
    const [userId, setUserId] = useState('');
    const [messageId, setMessageId] = useState('');
    const [target, setTarget] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    const userAgent = navigator.userAgent;

    const [t] = useTranslation("global");

    //for navigation bar visibility
    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);

    const getConversationList = async () => {
        // console.log('call123?')
        // console.log('userId::', userId)

        if (userId) {
            // console.log('got userid?')

            await domainName
                .serverAPICall(
                    domainName.debugAPI + `/community/listAllCommunityChatByUserId?UserId=${userId}`,
                    "GET"
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    // console.log('got maaaa?')

                    setConversationList(responseJson.Data)

                    setIsLoading2(false)

                }))
                .catch((error) => {
                    alert(
                        error
                    );
                });
        } else {
            // console.log('aaa?')

        }

        if (messageId && messageId != 1) {
            refreshChatList(messageId)
            // console.log('bbb?')
        } else {
            // console.log('ccc?')

        }

    }

    const goToChatBox = async (msgItem) => {
        let targetUserId = msgItem.sender._id === userId ? msgItem.receiver._id : msgItem.sender._id

        window.location.href = `/message?messageId=${targetUserId}&userId=${userId}&isTarget=${target}`;

    }
    const goBackChatList = async () => {
        window.location.href = `/message?userId=${userId}&isTarget=${target}`;
    }

    const refreshChatList = async (messageId) => {
        if (userId) {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + `/community/listChatMessagesById`,
                    "POST",
                    JSON.stringify({
                        "UserId": userId,
                        // MessageId: messageId
                        "TargetUserId": messageId
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    if (responseJson.ReturnCode === 200) {

                        setMessageList(responseJson)
                        setMessageCount(responseJson.Data.length);

                        setTargetUser({
                            UserId: responseJson.TargetUserId,
                            UserImage: responseJson.TargetUserImageDisplay,
                            UserName: responseJson.TargetUserName,
                        })

                    }

                    setIsLoading(false);

                }))
                // .then(scrollToBottom())
                // .then(scrollToBottom())
                .catch((error) => {
                    alert(
                        error
                    );
                });
        }

    }
    
    const scrollToBottom = () => {
        console.log('xxx')
        const container = messageListRef.current;
        //const lastMessageContainer = container.lastElementChild;

        // if (container) {
        //     container.scrollTo({
        //         top: container.scrollHeight,
        //         behavior: 'smooth'
        //     });
        //     console.log('container.scrollTop:', container.scrollTop);
        //     console.log('container.scrollHeight:', container.scrollHeight);
        // }

        if (container) {
            container.scrollTop = container.scrollHeight;
            console.log('container.scrollTop:', container.scrollTop);
            console.log('container.scrollHeight:', container.scrollHeight);
        }
    };


    // useEffect(() => {
    //     const checkForNewMessages = () => {
    //         refreshChatList(messageId);
    //         // console.log("123");
    //     };
    //     const intervalId = setInterval(checkForNewMessages, 3000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };

    //     scrollToBottom()
    // }, [messageId]);



    const handleSendMessage = () => {
        // Handle sending the message
        // You can use the messageInput state to access the input value
        // and perform the necessary actions (e.g., send the message to the server)
        console.log(messageInput);
        // Reset the input after sending the message
        sendMessage();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };

    const sendMessage = async () => {
        if (messageInput.trim() !== '') {

            console.log(messageInput);
            // Reset the input after sending the message
            setMessageInput('');

            if (targetUser.UserId) {
                await domainName
                    .serverAPICall(
                        domainName.debugAPI + `/community/createMessages`,
                        "POST",
                        JSON.stringify({
                            "Sender": userId,
                            "Receiver": targetUser.UserId,
                            "Message": messageInput
                        })
                    )
                    .then(domainName.handleServerAPICallErrors)
                    .then((responseJson => {
                        refreshChatList(targetUser.UserId);
                        scrollToBottom();
                        // setMessageList(responseJson.Data)

                    }))
                    .catch((error) => {
                        alert(
                            error
                        );
                    });
            }

        }
    };


    useEffect(() => {
        // refreshChatList(messageId)
        getConversationList()
    }, [onesignalId]);



    // useEffect(() => {
    //     // setTimeout(()=>{
    //     //     scrollToBottom();
    //     // },100);

    //     scrollToBottom();
    // }, [messageListRef]);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setView('mobile');
            } else if (width >= 768 && width < 1500) {
                setView('laptop');
            } else if (width >= 1500 && width <= 3000) {
                setView('monitor');
            } else {
                setView('default');
            }
        };
        //For the website be more responsive
        handleResize();
        // For resize
        window.addEventListener('resize', handleResize);
        // For cleaning up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 600); // set 600 to your own breakpoint
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsLoading(true)
        setIsLoading2(true)

        if (passInUserId) {
            setUserId(passInUserId)
            console.log('11')
        }
        if (passInMessageId) {
            setMessageId(passInMessageId)
            console.log('22')
        }


        let queryParams = queryString.parse(location.search);

        if (queryParams && queryParams.isTarget) {
            setTarget(queryParams.isTarget);
            // console.log('33')

        }

        if (queryParams && queryParams.userId) {
            setUserId(queryParams.userId)
            // console.log('33')

        }
        if (queryParams && (queryParams.messageId && queryParams.messageId != 1)) {
            setMessageId(queryParams.messageId)
            // console.log('44')

        }

        if (queryParams.userId && (queryParams.messageId && queryParams.messageId != 1)) {
            // let isIos = !!userAgent.match(/iPad|iPhone|iPod/)
            // let isAndroid = !!userAgent.match(/Android/)

            // if (isIos || isAndroid || view == 'mobile') {
            //     console.log('sini ?')

            if (view === 'mobile') {
                setShowTabNavigation(false);
            }

            getConversationList();
            const intervalId = setInterval(() => {
                getConversationList();
            }, 4000);

            return () => {
                clearInterval(intervalId);
            };
            // }
        } else {
            setIsLoading(false)
        }
        setShowTabNavigation(true);
        getConversationList()
    }, [userId, messageId])

    useEffect(() => {
        // Check if there are new messages
        if (messageCount > lastMessageCount) {
            scrollToBottom();
            setLastMessageCount(messageCount);
        }
    }, [messageCount, lastMessageCount]);

    return (
        <div>
            <Container className='chat-container'>
                <Row>
                    {view === 'mobile' && (userId && !messageId) && (

                        <Container>
                            <Row>
                                <div>
                                    {/* Conversation List */}
                                    <Col className='col-12 col-lg-5 pt-3'>
                                        <div>

                                            {isLoading2 ? (
                                                <img src={loadingGif} alt="Loading" style={{ width: '20px' }} />
                                            ) : (conversationList.map((item, index) => (
                                                <MessageUserCard
                                                    key={index}
                                                    photo={item.sender._id === userId ? item.receiver.ImageDisplay : item.sender.ImageDisplay}
                                                    name={item.sender._id === userId ? item.receiver.Name : item.sender.Name}
                                                    msg={item.message}
                                                    date={item.DisplayCreatedAt}
                                                    hasRead={item.seen}
                                                    unreadCount={item.TotalUnread}
                                                    onClick={() => {
                                                        goToChatBox(item);
                                                    }}
                                                />
                                            )))}

                                        </div>
                                    </Col>

                                </div>

                            </Row>
                        </Container>
                    )}

                    {view === 'mobile' && (userId && messageId) && (
                        <Container >
                            <Row>
                                {messageId && (
                                    <Container>
                                        <Row>
                                            <div className='chat-header'>
                                                <button
                                                    onClick={goBackChatList}
                                                    style={{
                                                        marginRight: '2%',
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    <img
                                                        src={backIcon}
                                                        alt='Back'
                                                        style={{ height: 20 }}
                                                    />
                                                </button>
                                                <strong style={{ alignContent: 'center' }}>
                                                    {isLoading ? (
                                                        <img src={loadingGif} alt="Loading" style={{ width: '20px' }} />
                                                    ) : (messageList && messageList.TargetUserName)}
                                                </strong>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className='chat-messages' ref={messageListRef}>
                                                <div>
                                                    {messageList && messageList.Data ? (
                                                        messageList.Data.map((item, index) => (
                                                            <MessageConversationCard
                                                                key={index}
                                                                photo={messageList.TargetUserImageDisplay}
                                                                name={messageList.TargetUserName}
                                                                msg={item.message}
                                                                date={item.DisplayCreatedAt}
                                                                onSide={item.sender === userId ? 'right' : 'left'}
                                                            />
                                                        ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className='chat-input-container'>
                                            <Col xs={9}>
                                                <Form.Control
                                                    as='input'
                                                    placeholder={t(
                                                        'notification.Type your message...'
                                                    )}
                                                    value={messageInput}
                                                    onChange={(e) =>
                                                        setMessageInput(e.target.value)
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </Col>

                                            <Col xs={3}>
                                                <Button
                                                    onClick={handleSendMessage}
                                                    className='message-send-btn'
                                                >
                                                    {t('notification.Send')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}
                            </Row>
                        </Container>
                    )}

                    {(view === 'laptop' || view === 'monitor' || view === 'default') && (
                        <>

                            <Col className='col-12 col-lg-5 pt-3'>

                                {isLoading2 ? (
                                    <img src={loadingGif} alt="Loading" style={{ width: '20px' }} />
                                ) : (conversationList.map((item, index) => (
                                    <MessageUserCard
                                        key={index}
                                        photo={item.sender._id === userId ? item.receiver.ImageDisplay : item.sender.ImageDisplay}
                                        name={item.sender._id === userId ? item.receiver.Name : item.sender.Name}
                                        msg={item.message}
                                        date={item.DisplayCreatedAt}
                                        hasRead={item.seen}
                                        unreadCount={item.TotalUnread}
                                        onClick={() => {
                                            goToChatBox(item);
                                        }}
                                    />
                                )))}

                            </Col>


                            <Col className='col-12 col-lg-7 pt-3' style={{ borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }}>

                                <Row>
                                    <Col className='fw-bold text-center'>

                                        {isLoading ? (
                                            <img src={loadingGif} alt="Loading" style={{ width: '20px' }} />
                                        ) : (messageList && messageList.TargetUserName)}

                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='p-0'>
                                        <hr style={{ borderTop: '1px solid #bbb' }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col ref={messageListRef} style={{ maxHeight: '450px', minHeight: '450px', overflowY: 'auto' }}>
                                        {messageList && messageList.Data ? (
                                            messageList.Data.map((item, index) => (
                                                <MessageConversationCard
                                                    key={index}
                                                    photo={messageList.TargetUserImageDisplay}
                                                    name={messageList.TargetUserName}
                                                    msg={item.message}
                                                    date={item.DisplayCreatedAt}
                                                    onSide={item.sender === userId ? 'right' : 'left'}
                                                />
                                            ))
                                        ) : (
                                            <></>
                                        )}

                                    </Col>


                                </Row>




                                <Row>
                                    <Col xs={12} className='d-flex'>
                                        {(view === 'laptop' || view === 'monitor' || view === 'default') && (
                                            <>
                                                <Form.Control
                                                    as='textarea'
                                                    rows={2}
                                                    placeholder={t('notification.Type your message...')}
                                                    value={messageInput}
                                                    onChange={(e) => setMessageInput(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                    style={{ flex: '1', maxHeight: '50px', minHeight: '50px', overflowY: 'auto', marginRight: '10px' }}
                                                />
                                                <Button onClick={handleSendMessage} style={{ height: '50px' }}>
                                                    {t('notification.Send')}
                                                </Button>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                        </>
                    )}
                </Row>
            </Container>
            <ToastContainer />

        </div>
    )
}
export default Chat