import React, { useEffect, useState } from 'react'
import { Link, Navigate, json, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import domainName from '../domainName';
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import { COLORS } from '../colorTheme/colorTheme';
import ReactStarRatings from 'react-star-ratings';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MetaTags from 'react-meta-tags';


const AdvisorDetails = () => {
  const [isMobile, setIsMobile] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

  const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [serviceID, setServiceID] = useState();

  const [textCopiedCode, setTextCopiedCode] = useState();

  const [posterUserId, setPosterUserId] = useState();
  const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);
  //data from API
  const [listingImageDisplay, setListingImageDisplay] = useState([])
  const [name, setName] = useState()
  const [userProfileImage, setUserProfileImage] = useState()
  const [favourite, setFavourite] = useState(false);
  const [categoryList, setCategoryDisplayList] = useState([]);
  const [serviceNameTitle, setServiceNameTitle] = useState();
  const [starRate, setStarRate] = useState();
  const [userReviewListCount, setUserReviewListCount] = useState()
  const [awardsNameDisplay, setAwardsNameDisplay] = useState([])
  const [certificatesNameDisplay, setCertificatesNameDisplay] = useState([])
  const [verified, setVerified] = useState(false);
  const [UserAwards, setUserAwards] = useState();
  const [serviceLevel, setServiceLevel] = useState();
  const [certificates, setCredentialCertificate] = useState([])
  const [location, setLocation] = useState([])
  const [description, setDescription] = useState()
  const [canWhatsapp, setCanWhatsapp] = useState(false)
  const [whatsappLink, setWhatsappLink] = useState()
  const [shareLink, setShareLink] = useState()
  const [reviews, setReviews] = useState([])
  const [view, setView] = useState('');
  // for modal book shcedule
  const [bookScheduleModalShow, setbookScheduleModalShow] = useState(false)
  const [showModal, setShowModal] = useState(false);

  // for calender
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [messageInput, setMessageInput] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [showAll, setShowAll] = useState(false);

  const [showMediaModal, setShowMediaModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const [t, i18n] = useTranslation("global");

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const openMediaModal = (media) => {
    setSelectedMedia(media);
    setShowMediaModal(true);
  };

  const closeMediaModal = () => {
    setSelectedMedia(null);
    setShowMediaModal(false);
  };
  const maxToShow = 3;

  const toggleShowMore = () => {
    setShowAll(!showAll);
  };
  //for time picker
  const [hour, setHour] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);



  //For divide design into three categories
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setView('mobile');
      } else if (width >= 768 && width < 1500) {
        setView('laptop');
      } else if (width >= 1500 && width <= 3000) {
        setView('monitor');
      } else {
        setView('default');
      }
    };

    const handleResize2 = () => {
      setIsWebsite(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize2);
    //For the website be more responsive
    handleResize();
    // For resize
    window.addEventListener('resize', handleResize);
    // For cleaning up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAddToFavorites = () => {
    // Add item to favorites logic
    // ...

    // Show toast message
    toast.success(t("advisorDetails.Added as favourite"), {
      icon: <img src={require("../img/icons/favouriteWithRedFilled.png")} alt="Favourite" style={{ width: '25px', height: '25px' }} />,
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
    });
  };

  const handleRemoveToFavorites = () => {
    // Add item to favorites logic
    // ...

    // Show toast message
    toast.success(t("advisorDetails.Removed from favorites"), {
      icon: <img src={require("../img/icons/favouriteWithWhiteFilled.png")} alt="Favourite" style={{ width: '25px', height: '25px' }} />,
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
    });
  };


  const handleAddToCopyLink = () => {

    toast.success(t("advisorDetails.Link Copied!"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
    });
  };

  const sendMessage = async () => {
    if (messageInput.trim() !== '') {
      console.log(messageInput);
      // Reset the input after sending the message
      setMessageInput('');


      await domainName
        .serverAPICall(
          domainName.debugAPI + `/community/createMessages`,
          "POST",
          JSON.stringify({
            "Sender": UserId,
            "Receiver": posterUserId,
            "Message": messageInput
          })
        )
        .then(domainName.handleServerAPICallErrors)
        .then((responseJson => {
          console.log(responseJson.Data)
          navigate(`/message?userId=${UserId}&messageId=${posterUserId}`)
        }))
        .catch((error) => {
          alert(
            error
          );
        });

    }
  };

  function renderSendMessageSection() {
    return (
      <Col style={{ background: COLORS.white, padding: isWebsite ? '2%' : '5%', marginBottom: isWebsite ? "65%" : "", borderRadius: '5px' }}>
        <Row>
          <div style={{ fontSize: 14, fontWeight: 'bold' }}>{t('advisorDetails.Send a message to ')}{name}</div>
        </Row>
        <Row className='mt-2'>
          <textarea
            style={{
              height: '200px',
              borderColor: COLORS.lightGray1,
              borderWidth: 1,
              borderRadius: 5,
              width: '100%',
              boxSizing: 'border-box',
              padding: '5px',
              fontSize: '16px',
              marginTop: '5%',
            }}
            placeholder={t('placeholder.Write a custom message...')}
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          ></textarea>
        </Row>

        <Row className='mt-3'>
          <button
            className="btn-white text-dark  fw-bold btn"
            style={{ borderColor: COLORS.gray }}
            onClick={
              () => {
                if (UserData == "") {
                  navigate('/signin')
                } else {
                  if (posterUserId == UserId) {
                    alert(t("error.I'm sorry, but you cannot engage in a conversation with yourself."))
                  } else {
                    sendMessage();
                  }

                }

              }
              // sendMessage
            }
          >
            {t('advisorDetails.Chat')}
          </button>
        </Row>

        <Row className='mt-3'>
          <button
            className="btn-white text-dark  fw-bold btn"
            style={{ borderColor: COLORS.gray }}
            onClick={(e) => {
              if (UserData == "") {
                navigate('/signin')
              } else {
                if (posterUserId == UserId) {
                  alert(t("error.I'm sorry, but you cannot engage in a conversation with yourself."))
                } else {
                  e.stopPropagation();
                  if (canWhatsapp) {
                    window.open(whatsappLink);
                  } else {
                    alert(t("error.Oops! This service provider didn't register WhatsApp"));
                  }
                }
              }
            }}
          >
            {t('advisorDetails.Whatsapp')}
          </button>
        </Row>

        <Row className='mt-3'>
          <Button onClick={() => {
            //console.log("UserData", UserData)
            //console.log("UserId", UserId)
            if (UserData == "") {
              navigate('/signin')
            } else {
              if (posterUserId == UserId) {
                alert(t("error.I'm sorry, but you cannot engage in a conversation with yourself."))
              } else {
                setbookScheduleModalShow(true)
              }
            }
          }}>
            {t('advisorDetails.Book for free consultation')}
          </Button>
        </Row>


      </Col>
    )
  }

  function renderCategories(categoryName) {
    return (
      <div style={{
        backgroundColor: COLORS.lightGray3,
        borderRadius: 20,
        padding: 3,
        marginRight: 5,
        marginBottom: 5
      }}>
        <span style={{ color: COLORS.black }}>{categoryName}</span>
      </div>
    )
  }


  function greyLine() {
    return (
      <div
        style={{
          borderBottom: '1px solid black',
          borderColor: '#D9D9D9',
          // margin: '10px 0',
        }}
      ></div>
    )
  }

  function BookScheduleModal() {
    return (
      <Modal show={bookScheduleModalShow} onHide={() => setbookScheduleModalShow(false)} fade={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('advisorDetails.Schedule')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 10 }}>
              <img src={listingImageDisplay[0]} alt="listingImage" className="img-fluid" style={{ borderRadius: 20, width: 80, height: 80 }} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              {categoryList.map((item, index) => (
                <div key={index} style={{ marginRight: 5 }}>
                  {renderCategories(item.CategoryName)}
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginLeft: 80, padding: 3 }}>
            {decodeURIComponent(description)}
          </div>
          <div style={{ marginTop: '3%' }}>
            <h4>{t('advisorDetails.Calendar')}</h4>
            <DatePicker selected={selectedDate} onChange={handleDateChange} inline minDate={new Date()} />
          </div>
          <div style={{ marginTop: '3%' }}>
            {renderTimeSelection()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" style={{ width: '100%' }} onClick={async () => {
            setLoading(true)
            //console.log('clicked confirm');
            const dateRange = selectedDate.toISOString().split('T')[0].toString()
            //console.log("dateRange", dateRange)
            //console.log("hour", hour)

            //   //console.log(JSON.stringify({
            //     "ServiceId":serviceID,
            //     "UserId": UserId,
            //     "PosterUserId": posterUserId,
            //     "ServiceName": serviceNameTitle,
            //     "Description": description,
            //     "ServiceScheduleDetails": {
            //         "ServiceTime": 1,
            //         "ServiceMinute": 0,
            //         "ServiceStartTime": `${dateRange}T${hour == 9 ? '09' : hour}:00:00.000Z`,
            //         "ServiceEndTime": `${dateRange}T${hour + 1}:00:00.000Z`
            //     }
            // }))

            await domainName
              .serverAPICall(
                domainName.debugAPI + "/service/userBookingService",
                "POST",
                JSON.stringify({
                  "ServiceId": serviceID,
                  "UserId": UserId,
                  "PosterUserId": posterUserId,
                  "ServiceName": serviceNameTitle,
                  "Description": decodeURIComponent(description),
                  "ServiceScheduleDetails": {
                    "ServiceTime": 1,
                    "ServiceMinute": 0,
                    "ServiceStartTime": `${dateRange}T${hour == 9 ? '09' : hour}:00:00.000Z`,
                    "ServiceEndTime": `${dateRange}T${hour + 1}:00:00.000Z`
                  }
                }),
                undefined, // user id, wait binglun settle backend
                37.785834, // latitude
                -122.406417 // longitude
              )
              .then(domainName.handleServerAPICallErrors)
              .then((responseJson => {
                //console.log("responseJson /service/userBookingService ->", responseJson);
                if (responseJson.Data != null && responseJson.ReturnCode == 200) {
                  alert(t('error.Successfully scheduled booking'))
                  //navigate to booking page 
                  navigate('/mybookings')
                }
              }))
              .catch((error) => {
                //console.log("error /service/userBookingService ->", error);
              });


          }} disabled={loading}>
            {loading ? t('advisorDetails.Loading...') : t('advisorDetails.Book')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function renderTimeSelection() {
    const timeSlots = [
      { label: '09:00AM', hour: 9 },
      { label: '10:00AM', hour: 10 },
      { label: '11:00AM', hour: 11 },
      { label: '12:00PM', hour: 12 },
      { label: '1:00PM', hour: 13 },
      { label: '2:00PM', hour: 14 },
      { label: '3:00PM', hour: 15 },
      { label: '4:00PM', hour: 16 },
      { label: '5:00PM', hour: 17 },
    ];

    const handleTimeSlotSelection = (hour) => {
      setHour(hour);
      setSelectedTimeSlot(hour);
    };

    return (
      <div style={{ margin: '10px 0' }}>
        <h4>Time</h4>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {timeSlots.map((slot, index) => (
            <div key={index} style={{ marginRight: '5%' }}>
              {renderTimeSlot(slot.label, () => handleTimeSlotSelection(slot.hour), index)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderTimeSlot(timeDisplay, onPress, index) {
    const isSelected = index + 9 === selectedTimeSlot;

    return (
      <button
        onClick={onPress}
        style={{
          padding: '10px',
          backgroundColor: isSelected ? COLORS.lightOrange : '#E8E8E8',
          borderColor: isSelected ? COLORS.orange : '1px solid #E8E8E8',
          borderRadius: '5px',
          borderWidth: isSelected ? 1 : 0,
          marginBottom: '10px',
          width: '120px',
          height: '45px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',

        }}
      >
        <span style={{ color: isSelected ? COLORS.orange : '#000000' }}>{timeDisplay}</span>
      </button>
    );
  }


  function renderReviews() {
    return (
      <Row className="mt-3">
        <Col className="col-12 col-lg-12" style={{
          padding: isWebsite ? '2%' : '5%'
        }}>
          <h5>{t('advisorDetails.Reviews')}</h5>
          {reviews ? (
            <div style={{ color: COLORS.darkGray }}>{t("MyReviews.NoReview")}</div>
          ) : (
            reviews.map((e, index) => (
              <div style={{ cursor: 'pointer' }} onClick={() => {
                // //console.log('should go profile page ') 
                navigate(`/myProfile?userId=${e.TargetUserId ? e.TargetUserId : e.ReviewerUserId}`)
              }}>
                <Row key={index} className="mt-3">
                  <Col xs={8} lg={9} className="d-flex flex-column"
                  //style={{ backgroundColor: COLORS.blue }}
                  >
                    <Row>
                      <img
                        src={e.ReviewerImageDisplay}
                        alt="Image"
                        className="img-fluid"
                        style={{ width: '100%', height: 'auto', maxWidth: '90px', maxHeight: '70px', borderRadius: 0 }}
                      />
                      <Col>
                        <Row style={{ marginLeft: '0.2%' }}>{e.ReviewerName}</Row>
                        <Row>
                          <ReactStarRatings
                            rating={e.Likes}  // Set the initial rating value
                            starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                            numberOfStars={5}  // Set the total number of stars to display
                            starDimension="20px"  // Set the size of each star
                            starSpacing="1px"  // Set the spacing between each sta
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4} lg={3} className="d-flex align-items-center justify-content-end">
                    {e.CreatedTimestampDisplay}
                  </Col>
                  <Col xs={12}>
                    <div style={{ marginTop: '2%' }}>{e.ReviewMessage}</div>
                  </Col>
                  <Col style={{ padding: '1%', paddingTop: isWebsite ? '2%' : '5%' }}>
                    {greyLine()}
                  </Col>

                </Row>
              </div>
            ))
          )}
        </Col>
      </Row>
    )
  }

  function renderDescription() {
    const descriptionLines = decodeURIComponent(description).split('\n');

    const renderTextParts = (description) => {
      const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
      const parts = description?.split(urlRegex);

      return parts?.map((part, index) => {
        if (part.match(urlRegex)) {
          const href = part.startsWith('www') ? `http://${part}` : part;
          return (
            <a key={index} href={href} onClick={(e) => handleLinkPress(e, href)}>
              {part}
            </a>
          );
        } else {
          return (
            <span key={index}>
              {part}
            </span>
          );
        }
      });
    };


    const handleLinkPress = (e, url) => {
      e.preventDefault();
      window.open(url, '_blank');
    };

    // Function to check if there is any link in the description
    const containsLink = (description) => {
      const urlRegex = /^(https?:\/\/|www\.)/i;
      return urlRegex.test(description);
    };

    // Example usage of renderTextParts and containsLink
    const ExampleComponent = ({ description }) => {
      const hasLink = containsLink(description);

      return (
        <div>
          {hasLink ? (
            renderTextParts(description)
          ) : (
            <span >{description}</span>
          )}
        </div>
      );
    };

    return (
      <Row className="mt-3">
        <Col className="col-12 col-lg-12" style={{ padding: isWebsite ? '2%' : '5%' }}>
          <h5>{t('advisorDetails.Description')}</h5>
          {descriptionLines.map((line, index) => (
            <ExampleComponent description={line} key={index} />
          ))}
          {/* <p key={index}>{line}</p>
          <ExampleComponent description={description} /> */}
          <div style={{ marginTop: '3%' }}>{greyLine()}</div>
        </Col>
      </Row>
    );
  }

  function renderInfoContentLabel() {
    return (
      <Row className="mt-2" >
        <Col className="col-12 col-lg-6 me-3 mb-3" style={{
          //backgroundColor: COLORS.lightGray1, 
          padding: '5%'
        }}>

          <Col>
            <div>
              <h5>{t('advisorDetails.Credentials')}</h5>
            </div>
            <div>
              {certificatesNameDisplay.map((e, index) =>
                <Row key={index}>
                  <div style={{ color: COLORS.gray, padding: 5 }}><Image alt="listingImage" className="img-fluid" style={{ width: 20, height: 15, marginRight: 10 }} src={require("../img/icons/credential.png")} />
                    {e.CertificatesName} {t('advisorDetails.Certificates')}</div>
                </Row>
              )}
            </div>
            {awardsNameDisplay.map((e, index) =>
              <Row key={index}>
                <div style={{ color: COLORS.gray, padding: 5 }}><Image alt="listingImage" className="img-fluid" style={{ width: 20, height: 15, marginRight: 10 }} src={require("../img/icons/credential.png")} />
                  {e.AwardsName} {t('advisorDetails.Awards')}</div>
              </Row>
            )}
          </Col>

          <Col className="mt-5">
            <h5>
              {t('advisorDetails.Specialize in')}
            </h5>
            {
              categoryList.map((e, index) =>
                <div className='mt-2'>
                  <Row key={index} >
                    <div style={{
                      //Color: COLORS.darkBlue, 
                    }}>{e.CategoryName}: </div>
                  </Row>
                  <Row>
                    <div style={{
                      flex: 1,
                      //backgroundColor: COLORS.blue,
                    }}>{e.SubCateogrynameDisplay}</div>
                  </Row>
                </div>
              )
            }
          </Col>
        </Col>

        <Col className="col-12 col-lg-5" style={{
          //backgroundColor: COLORS.gray2, 
          padding: isWebsite ? '2%' : '5%', paddingRight: 0
        }}>
          <div>
            {location !== undefined && location.length > 0 ? (
              location.map((e, index) => (
                <div key={index}>
                  {(!showAll && index >= maxToShow) ? (
                    null
                  ) : (
                    <div>
                      <h5><br></br>{t('advisorDetails.Business Location')} {index + 1}</h5>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(e.Location.Address)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: '10px', color: '#20B6D2', textDecoration: 'none', cursor: 'pointer' }}
                        >
                          {e.Location.Address}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : ''}

            {location.length > maxToShow && (
              <span
                onClick={toggleShowMore}
                style={{
                  cursor: 'pointer',
                  color: showAll ? 'red' : 'orange',
                  marginTop: '5px',
                  textDecoration: showAll ? 'underline' : 'underline',
                }}
              >
                {showAll ? 'Show Less' : 'Show More'}
              </span>
            )}
          </div>

          <Col className="mt-5">
            <h5>{t('advisorDetails.Media')}</h5>
            <Row xs={2} sm={2} md={2} lg={3} className="g-3">

              {listingImageDisplay.map((media, index) => (
                <Col key={index}>
                  {typeof media === 'string' && (media.endsWith('.mp4') || media.endsWith('.quicktime')) ? (
                    <div className="mt-3" onClick={() => openMediaModal(media)}>
                      <div
                        className="embed-responsive embed-responsive-16by9"
                        style={{
                          display: 'block',
                          width: '100%',
                          padding: 0,
                          overflow: 'hidden',
                        }}
                      >
                        {
                          view == "mobile" &&
                          <video
                            controls
                            className="embed-responsive-item"
                            style={{
                              width: '150px',
                              borderRadius: '15px',
                              padding: 0,
                              margin: 0,
                              objectFit: 'cover',
                              height: '150px',
                              maxHeight: '100%',

                            }}
                          >
                            {media.endsWith('.mp4') ? <source src={media} type="video/mp4" /> : <source src={media} type="video/quicktime" />}
                          </video>
                        }
                        {
                          view == "laptop" &&
                          <video
                            controls
                            className="embed-responsive-item"
                            style={{
                              width: '100%',
                              borderRadius: '15px',
                              padding: 0,
                              margin: 0,
                              objectFit: 'cover',
                              height: '120px',
                              maxHeight: '120px',
                              boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                            }}
                          >
                            {media.endsWith('.mp4') ? <source src={media} type="video/mp4" /> : <source src={media} type="video/quicktime" />}
                          </video>
                        }
                        {
                          view == "monitor" &&
                          <video
                            controls
                            className="embed-responsive-item"
                            style={{
                              width: '100%',
                              borderRadius: '15px',
                              padding: 0,
                              margin: 0,
                              objectFit: 'cover',
                              height: '150px',
                              maxHeight: '150px',
                              boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                            }}
                          >
                            {media.endsWith('.mp4') ? <source src={media} type="video/mp4" /> : <source src={media} type="video/quicktime" />}
                          </video>
                        }
                        {

                          view == "default" &&
                          <video
                            controls
                            className="embed-responsive-item"
                            style={{
                              width: '100%',
                              borderRadius: '15px',
                              padding: 0,
                              margin: 0,
                              objectFit: 'cover',
                              height: 'auto',
                              maxHeight: '100%',
                              boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                            }}
                          >
                            {media.endsWith('.mp4') ? <source src={media} type="video/mp4" /> : <source src={media} type="video/quicktime" />}
                          </video>
                        }

                      </div>
                    </div>
                  ) : (
                    <div className="mt-3" onClick={() => openMediaModal(media)}>
                      {
                        view == "mobile" &&
                        <img

                          src={media}
                          alt="Image"
                          className="img-fluid"
                          style={{
                            width: '150px',
                            borderRadius: '15px',
                            padding: 0,
                            margin: 0,
                            objectFit: 'cover',
                            height: '150px',
                            maxHeight: '100%',
                          }}
                        />
                      }
                      {
                        view == "laptop" &&
                        <img

                          src={media}
                          alt="Image"
                          className="img-fluid"
                          style={{
                            width: '100%',
                            borderRadius: '15px',
                            padding: 0,
                            margin: 0,
                            objectFit: 'cover',
                            height: '120px',
                            maxHeight: '120px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                          }}
                        />
                      }
                      {
                        view == "monitor" &&
                        <img

                          src={media}
                          alt="Image"
                          className="img-fluid"
                          style={{
                            width: '100%',
                            borderRadius: '15px',
                            padding: 0,
                            margin: 0,
                            objectFit: 'cover',
                            height: '150px',
                            maxHeight: '150px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                          }}
                        />
                      }
                      {
                        view == "default" &&
                        <img

                          src={media}
                          alt="Image"
                          className="img-fluid"
                          style={{
                            width: '100%',
                            borderRadius: '15px',
                            padding: 0,
                            margin: 0,
                            objectFit: 'cover',
                            height: '100px',
                            maxHeight: '100px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                          }}
                        />
                      }
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Col>
        <Modal show={showMediaModal} onHide={closeMediaModal} centered>
          {/* <Modal.Header closeButton>
            <Modal.Title>Media</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            {selectedMedia && (
              selectedMedia.endsWith('.mp4') || selectedMedia.endsWith('.quicktime') ? (
                <video
                  controls
                  src={selectedMedia}
                  alt="Video"
                  style={{ width: '100%', maxHeight: '80vh' }}
                />
              ) : (
                <img
                  src={selectedMedia}
                  alt="Image"
                  style={{ width: '100%', maxHeight: '80vh' }}
                />
              )
            )}
          </Modal.Body>
        </Modal>
      </Row>
    );
  }

  const getImageSize = (view) => {
    switch (view) {
      case 'mobile':
        return { size: '150px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' };
      case 'laptop':
      case 'default':
        return { size: '150px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' };
      case 'monitor':
        return { size: '200px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' };
      default:
        return { size: '150px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' };
    }
  };

  const imageStyle = getImageSize(view);

  function renderCoverImageLabel() {
    return (
      <Row className="mt-3 p-3" style={{ borderRadius: '10px', backgroundColor: '#fff' }}>
        <Col xs={6} md={3} className="d-flex justify-content-center">
          <div style={{
            width: imageStyle.size,
            height: imageStyle.size,
            overflow: 'hidden',
            borderRadius: '20px',
            boxShadow: imageStyle.boxShadow,
          }}>
            <Image
              src={listingImageDisplay[0]}
              alt="listingImage"
              className="img-fluid"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        </Col>
        <Col xs={6} md={6}>
          <div className="d-flex flex-column">
            <h4 className="mb-1 ">{name}</h4>
            <div className="d-flex align-items-center mb-2">
              <span style={{ color: COLORS.orange, marginRight: '5px', fontSize: '1.2em' }}>{starRate}</span>
              <ReactStarRatings
                rating={starRate}
                starRatedColor={COLORS.orange}
                numberOfStars={5}
                starDimension="20px"
                starSpacing="1px"
              />
              <span style={{ marginLeft: '5px' }}>({userReviewListCount})</span>
            </div>
            <div className="d-flex flex-wrap align-items-center mb-2">
              {verified && (
                <div className="d-flex align-items-center me-2">
                  <Image src={require("../img/icons/verified2.png")} alt="Verified" width={15} height={15} className="me-1" />
                  <span>{verified}</span>
                </div>
              )}
              {serviceLevel && (
                <div className="d-flex align-items-center me-2">
                  <Image src={require("../img/icons/premier.png")} alt="Service Level" width={15} height={15} className="me-1" />
                  <span>{serviceLevel}</span>
                </div>
              )}
              {UserAwards && (
                <div className="d-flex align-items-center">
                  <Image src={require("../img/icons/gold.png")} alt="Award" width={15} height={15} className="me-1" />
                  <span>{UserAwards}</span>
                </div>
              )}
            </div>
            <Col xs={12} md={6} lg={4}>
              <button type="button" class="input-group btn" style={{
                backgroundColor: COLORS.white,
                marginTop: '10px',
                color: COLORS.black,
                borderWidth: 1,
                borderColor: COLORS.black
              }} onClick={() => {
                navigate(`/myProfile?userId=${posterUserId}`)
              }}>
                {t('advisorDetails.View Profile')}
              </button>
            </Col>

          </div>
        </Col>

        {/* <Col xs={12} md={3} className="d-flex flex-column">
          <h5>Location</h5>
          <p>#01-12 Hougang Mall Avenue 7 543210 Singapore</p>
          <h5>Media</h5>
          <div className="d-flex">
            <Image src={require("../img/icons/sample1.png")} alt="Media 1" className="me-2" width={60} height={60} />
            <Image src={require("../img/icons/sample2.png")} alt="Media 2" className="me-2" width={60} height={60} />
            <Image src={require("../img/icons/sample3.png")} alt="Media 3" width={60} height={60} />
          </div>
        </Col> */}
      </Row>
    );
  }

  function renderTitleLabel() {
    const openWhatsApp = () => {
      if (!isMobile) {
        const whatsappWebURL = `https://web.whatsapp.com/send?text=${shareLink}`;
        window.open(whatsappWebURL);
      } else {

        const whatsappAppURL = `whatsapp://send?text=${shareLink}`;
        window.location.href = whatsappAppURL;
      }
      handleCloseModal();

    };

    const openFacebook = () => {
      // const _link = `https://adviser2u.com/servicedetails?serviceid=${serviceID}`;
      // const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${_link}`;
      const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`;
      window.open(facebookURL)
      handleCloseModal();
    };

    const openTwitter = () => {
      const instagramWebURL = `https://twitter.com/intent/tweet?text=${shareLink}`;
      window.open(instagramWebURL);
      handleCloseModal();

    };

    const openLinkedIn = () => {
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`);
      handleCloseModal();

    };

    const openTelegram = () => {
      if (!isMobile) {
        const telegramWebURL = `https://t.me/share/url?url=${shareLink}`;
        window.open(telegramWebURL);
      } else {
        window.location.href = `tg://msg?text=${shareLink}`;
      }
      handleCloseModal();

    };

    const download = () => {
      navigator.clipboard.writeText(shareLink);
      handleCloseModal();
      handleAddToCopyLink();

      // setTextCopiedCode('Link Copied!')

      // setTimeout(() => {
      //   setTextCopiedCode('')
      // }, 2000);
    };

    return (
      <Row style={{ marginLeft: "1%" }}>
        {/* <MetaTags>
          <meta property="og:title" content={serviceNameTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={userProfileImage} />
          <meta property="og:url" content={`https://adviser2u.com/servicedetails?serviceid=${serviceID}`} />
        </MetaTags> */}

        <ToastContainer />

        <Col className="col-10"
        //style={{ backgroundColor: COLORS.blue }}
        >
          <h4>{serviceNameTitle}</h4>
        </Col>
        <Col className="col-2">
          <Row style={{ justifyContent: 'flex-end' }}>
            <Col style={{
              //backgroundColor: COLORS.green,
              display: 'flex', justifyContent: 'flex-end'
            }}>

              <div onClick={() => {
                if (UserData == "") {
                  navigate('/signin')
                } else {
                  if (posterUserId == UserId) {
                    alert(t("error.I'm sorry, but you cannot engage in a conversation with yourself."))
                  } else {
                    clickedFavouriteButton();
                  }
                }
                // clickedFavouriteButton()
              }} style={{ cursor: 'pointer', marginRight: 20 }}>
                <Image src={favourite ? require("../img/icons/favouriteWithRedFilled.png") : require("../img/icons/favouriteWithWhiteFilled.png")} alt="Star" width={38} height={38} />
              </div>

              <div onClick={() => {
                if (UserData == "") {
                  navigate('/signin')
                } else {
                  handleOpenModal()
                }
              }} style={{ cursor: 'pointer' }}>
                <Image src={require('../img/icons/share.png')} alt="Share" width={38} height={38} />
              </div>

              <div>
                {view == 'mobile' &&
                  <span style={{ fontSize: 8, color: COLORS.black, position: 'absolute', marginTop: 40, marginLeft: -40 }}>{textCopiedCode}</span>
                }
              </div>
              <div>
                {
                  view == 'laptop' &&
                  <span style={{ fontSize: 10, color: COLORS.black, position: 'absolute', marginTop: 40, marginLeft: -40 }}>{textCopiedCode}</span>
                }
              </div>
              <div>
                {
                  view == 'monitor' &&
                  <span style={{ fontSize: 10, color: COLORS.black, position: 'absolute', marginTop: 40, marginLeft: -40 }}>{textCopiedCode}</span>
                }
              </div>
              <div>
                {
                  view == 'default' &&
                  <span style={{ fontSize: 10, color: COLORS.black, position: 'absolute', marginTop: 40, marginLeft: -40 }}>{textCopiedCode}</span>
                }
              </div>
              <Modal show={showModal} onHide={handleCloseModal} style={{ marginTop: "10%" }}>
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontWeight: "bold" }}>{t('advisorDetails.Share to...')}</Modal.Title>
                </Modal.Header>
                {isWebsite &&
                  <Modal.Body>
                    <div className="text-center mt-3" style={{ marginTop: "10%", marginBottom: "10%", paddingLeft: "5%", paddingRight: "5%" }}>
                      {/* Share buttons */}
                      <div className="row share-icons">
                        <div className="col-2 share-icon">
                          <a href="#" style={{ textDecoration: 'none', color: '#333', }} onClick={download}>
                            <Image className='mb-3' src={require("../img/icons/copy_icon.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                            <div className="share-label" style={{ fontSize: "12px", alignItems: "center", whiteSpace: "nowrap" }}>{t('advisorDetails.Copy link')}</div>

                          </a>
                        </div>
                        <div className="col-2 share-icon">
                          <a href="#" style={{ textDecoration: 'none', color: '#333', }} onClick={openWhatsApp}>
                            <Image className='mb-3' src={require("../img/icons/wa.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                            <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.WhatsApp')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon">
                          <a href="#" style={{ textDecoration: 'none', color: '#333', }} onClick={openFacebook}>
                            <Image className='mb-3' src={require("../img/icons/facebook.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                            <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.Facebook')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon">
                          <a href="#" style={{ textDecoration: 'none', color: '#333', }} onClick={openTwitter}>
                            <Image className='mb-3' src={require("../img/icons/twitter_icon.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                            <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('X')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon">
                          <a href="#" style={{ textDecoration: 'none', color: '#333', }} onClick={openLinkedIn}>
                            <Image className='mb-3' src={require("../img/icons/in.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                            <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.LinkedIn')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon">
                          <a href="#" style={{ textDecoration: 'none', color: '#333', }} onClick={openTelegram}>
                            <Image className='mb-3' src={require("../img/icons/telegram.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                            <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.Telegram')}</div>
                          </a>
                        </div>
                        {/* Add more share icons with labels as needed */}
                      </div>
                    </div>
                  </Modal.Body>
                }
                {isWebsite == false &&
                  <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="text-center mt-3" style={{ marginTop: '10%', marginBottom: '10%' }}>
                      {/* Share buttons */}
                      <div className="row share-icons" style={{ display: 'flex', justifyContent: 'center', }}>
                        <div className="col-2 share-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: "35%" }}>
                          <a href="#" style={{ textDecoration: 'none', color: '#333' }} onClick={download}>
                            <Image className='mb-3' src={require("../img/icons/copy_icon.png")} width={55} height={55} />
                            <div className="share-label" style={{ fontSize: '12px', alignItems: 'center', whiteSpace: 'nowrap' }}>{t('advisorDetails.Copy link')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: "35%" }}>
                          <a href="#" style={{ textDecoration: 'none', color: '#333' }} onClick={openWhatsApp}>
                            <Image className='mb-3' src={require("../img/icons/wa.png")} width={55} height={55} />
                            <div className="share-label" style={{ fontSize: '12px', alignItems: 'center' }}>{t('referRewards.WhatsApp')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <a href="#" style={{ textDecoration: 'none', color: '#333' }} onClick={openFacebook}>
                            <Image className='mb-3' src={require("../img/icons/facebook.png")} width={55} height={55} />
                            <div className="share-label" style={{ fontSize: '12px', alignItems: 'center' }}>{t('referRewards.Facebook')}</div>
                          </a>
                        </div>
                      </div>
                      <div className="row share-icons" style={{ display: 'flex', justifyContent: 'center', paddingTop: "10%" }}>
                        <div className="col-2 share-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: "35%" }}>
                          <a href="#" style={{ textDecoration: 'none', color: '#333' }} onClick={openTwitter}>
                            <Image className='mb-3' src={require("../img/icons/twitter_icon.png")} width={55} height={55} />
                            <div className="share-label" style={{ fontSize: '12px', alignItems: 'center' }}>{t('X')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: "35%" }}>
                          <a href="#" style={{ textDecoration: 'none', color: '#333' }} onClick={openLinkedIn}>
                            <Image className='mb-3' src={require("../img/icons/in.png")} width={55} height={55} />
                            <div className="share-label" style={{ fontSize: '12px', alignItems: 'center' }}>{t('referRewards.LinkedIn')}</div>
                          </a>
                        </div>
                        <div className="col-2 share-icon" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <a href="#" style={{ textDecoration: 'none', color: '#333' }} onClick={openTelegram}>
                            <Image className='mb-3' src={require("../img/icons/telegram.png")} width={55} height={55} />
                            <div className="share-label" style={{ fontSize: '12px', alignItems: 'center' }}>{t('referRewards.Telegram')}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                }
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  function renderInfoSection() {
    return (
      <Col xs={20} sm={15} md={12} lg={11} xl={9} style={{ background: COLORS.white, padding: isWebsite ? '2%' : '5%', marginRight: isWebsite ? '2%' : '5%', marginLeft: isWebsite ? '1%' : '1%', borderRadius: '5px', marginBottom: isWebsite ? '2%' : '5%' }}>
        {renderTitleLabel()}
        {renderCoverImageLabel()}
        {renderInfoContentLabel()}
        {renderDescription()}
        {renderReviews()}
      </Col>
    )
  }


  async function clickedFavouriteButton() {

    let apiName1 = "/user/insertUserFavouriteList";
    let apiName2 = "/user/deleteUserFavouriteList";

    await domainName
      .serverAPICall(
        domainName.debugAPI + `${favourite ? apiName2 : apiName1}`,
        "POST",
        JSON.stringify({
          "UserId": UserId,
          "TargetUserId": posterUserId,
          "ServiceId": serviceID
        }),
        UserId,
        // latitude,
        // longitude

      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        ////console.log(`${favourite ? apiName2 : apiName1} response : `, responseJson)

        if (responseJson.ReturnCode == 200) {
          //set the love looking, white or red, instant change
          setFavourite(!favourite);
          if (favourite == false) {
            handleAddToFavorites();
          } else {
            handleRemoveToFavorites();
          }
        }

      }))
      .catch((error) => {
        ////console.log('/user/insertUserFavouriteList or /user/deleteUserFavouriteList got error : ', error)
      });
  }

  async function getExploreServiceDetailsById(serviceid) {

    if (serviceid) {
      setServiceID(serviceid)
    }

    await domainName
      .serverAPICall(
        domainName.debugAPI + "/service/exploreServiceDetailsById",
        "POST",
        JSON.stringify({
          "ServiceId": serviceid ? serviceid : serviceID
        }),
        UserId, // user id, wait binglun settle backend
        //userState.latitude, // latitude
        //userState.longitude // longitude

      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {

        ////console.log('/service/exploreServiceDetailsById : response : ', responseJson.Data)
        ////console.log('responseJson.Data.UsersReviewData.ReceivedReviewList -> ', responseJson.Data.UsersReviewData.ReceivedReviewList)

        setPosterUserId(responseJson.Data.PosterUserId)
        setAwardsNameDisplay(responseJson.Data.Awards) //AwardsName
        setCertificatesNameDisplay(responseJson.Data.Certificates) //CertificatesName
        setListingImageDisplay(responseJson.Data.ListingImageDisplay)
        setName(responseJson.Data.UsersData.Name)
        setUserProfileImage(responseJson.Data.UsersData.Image ? `https://d2p7a0wwlmqiu3.cloudfront.net/profile/${responseJson.Data.UsersData.Image}` : 'https://adviser2u.com/static/media/logo.8ad0b452ae80fe75f168.png')
        setCategoryDisplayList(responseJson.Data.CategoryDisplayList)
        setFavourite(responseJson.Data.IsFavouriteList)
        setServiceNameTitle(responseJson.Data.ServiceName)
        setStarRate(responseJson.Data.UsersReviewData.UserRating)
        setUserReviewListCount(responseJson.Data.UsersReviewData.UserReviewListCount)
        setVerified(responseJson.Data.UsersData.IsVerify)
        setUserAwards(responseJson.Data.UsersData.UserAwards)
        setServiceLevel(responseJson.Data.UsersData.ServiceLevel)
        setCredentialCertificate(responseJson.Data.ListingImage) // this one not yet ready from API
        setLocation(responseJson.Data.ServiceLocationData)
        setDescription(responseJson.Data.Description)
        setReviews(responseJson.Data.UsersReviewData.ReceivedReviewList)
        setCanWhatsapp(responseJson.Data.CanWhatsapps)
        setWhatsappLink(responseJson.Data.WhatappsLink)
        setShareLink(responseJson.Data.DynamicLink)

      }))
      .catch((error) => {
        ////console.log("/service/exploreServiceDetailsById : error :", error)
      });
  }

  useEffect(() => {

    // Get the URLSearchParams object from the current URL
    const searchParams = new URLSearchParams(window.location.search);

    // Get the value of the 'serviceid' parameter
    const serviceId = searchParams.get('serviceid');

    // Do something with the serviceId value
    //console.log("serviceId in advisordetails page", serviceId);

    getExploreServiceDetailsById(serviceId)
  }, [UserData, UserId]);


  return (
    <div style={{ backgroundColor: "#F8F8F8", paddingTop: isWebsite ? '2%' : '' }}>


      <Container className={isWebsite ? "width95" : ""}>
        <Row>
          {renderInfoSection()}
          {renderSendMessageSection()}
        </Row>

        <BookScheduleModal />
      </Container>
    </div>
  )
}

export default AdvisorDetails