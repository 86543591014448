import React, { useState, useEffect } from 'react'
import domainName from '../../domainName'
import { useSelector } from 'react-redux';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { COLORS } from "../../colorTheme/colorTheme";
import { useNavigate } from 'react-router-dom';
import { SHA256 } from 'crypto-js';
import { useTranslation } from "react-i18next";

const NewRequestDetails = () => {

    const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);

    const navigate = useNavigate();

    const [requestId, setRequestId] = useState()
    //data from api
    const [requestIdNo, setRequestIdNo] = useState()
    const [targetUserId, setTargetUserId] = useState()
    const [displayDate, setDisplayDate] = useState();
    const [userName, setUserName] = useState();
    const [categoryDisplay, setCategoryDisplay] = useState();
    const [subcategoryDisplay, setSubcategoryDisplay] = useState();
    const [locationDisplay, setLocationDisplay] = useState();
    const [description, setDescription] = useState();
    const [t] = useTranslation("global");


    async function getRequestDetails(requestid) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/advisorSelectUserRequestDetailsById",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "UserRequestId": requestid ? requestid : requestId
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                setRequestId(requestid)
                // console.log('/advisorSelectUserRequestDetailsById : response : ', responseJson.Data)
                setTargetUserId(responseJson.Data[0].UsersData._id)
                setRequestIdNo(responseJson.Data[0].UserRequestData.RequestIdNo)
                setDisplayDate(responseJson.Data[0].DisplayDateTime)
                setUserName(responseJson.Data[0].UsersData.Name)
                setCategoryDisplay(responseJson.Data[0].UserRequestData.CategoryDisplayList[0].CategoryName)
                setSubcategoryDisplay(responseJson.Data[0].UserRequestData.CategoryDisplayList[0].SubCateogrynameDisplay)
                setLocationDisplay(responseJson.Data[0].DisplayLocation)
                setDescription(responseJson.Data[0].UserRequestData.ExtraInfo)
            }))
            .catch((error) => {
                // console.log('/advisorSelectUserRequestDetailsById : error : ', error)
            });
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '1.8%',
                    marginTop: '1%',
                }}
            />
        );
    }

    useEffect(() => {
        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const requestId = searchParams.get('requestId');

        // Do something with the serviceId value
        // console.log("requestId in advisordetails page", requestId);

        getRequestDetails(requestId)
    }, [])

    return (
        <div style={{ backgroundColor: "#F8F8F8", paddingTop: '2%', height: 1200 }}>
            <Container className="width95" style={{ backgroundColor: COLORS.white, paddingBottom: '2%', paddingTop: '1%' }}>
                <Row>
                    <Col>
                        <h1>{t('NewRequestDetails.Request Details')}</h1>
                        <br />
                        {greyLine()}
                    </Col>
                </Row>


                {/* <div>{JSON.stringify(advisorSfavouriteListingerviceListings)}</div> */}

                <Row style={{ margin: '1%', padding: '1%' }}>
                    <div style={{
                        backgroundColor: COLORS.lightGray1, borderRadius: 5, padding: '4%', flexDirection: 'column'
                    }}>
                        <Row><h4>{t('NewRequestDetails.Request No')}: {requestIdNo}</h4></Row>
                        <Row><h4>{t('NewRequestDetails.Request Date')}: {displayDate}</h4></Row>
                    </div>
                </Row>

                <Row className='mt-2 m-1'>
                    <Col><img src={require('../../img/icons/user.png')} style={{ height: 35, width: 35 }} /> {userName}</Col>
                </Row>

                <Row className='mt-2 m-1'>
                    <Col><img src={require('../../img/icons/categories.png')} style={{ height: 35, width: 35 }} /> {categoryDisplay} : {subcategoryDisplay}</Col>
                </Row>

                <Row className='mt-2 m-1'>
                    <Col><img src={require('../../img/icons/location.png')} style={{ height: 35, width: 35 }} /> {locationDisplay}</Col>
                </Row>

                <Row className='mt-2 m-1'>
                    <Col><img src={require('../../img/icons/description.png')} style={{ height: 35, width: 35 }} /> {description}</Col>
                </Row>

                <Row className='mt-2 m-1' style={{marginBottom:'3%'}}>
                    <Col>
                        <button type="button" class="input-group btn" style={{
                            backgroundColor: COLORS.white,
                            color: COLORS.black,
                            borderWidth: 1,
                            borderColor: COLORS.black
                        }} onClick={() => {
                            navigate(`/myProfile?userId=${targetUserId}`)
                        }}>
                            {t('NewRequestDetails.View Profile')}
                        </button>
                    </Col>

                    <Col>
                        <button type="button" class="input-group btn" style={{
                            backgroundColor: COLORS.white,
                            color: COLORS.black,
                            borderWidth: 1,
                            borderColor: COLORS.black
                        }} onClick={() => {
                            let currentTime = new Date().getTime(); //epoch timestamp now
                            const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime)
                            navigate(`/message?userId=${UserId}&messageId=${targetUserId}&uid=${resultEncrypted}&tid=${currentTime}`)
                        }}>
                            {t('NewRequestDetails.Chat')}
                        </button>
                    </Col>

                </Row>



            </Container>
        </div>
    )
}

export default NewRequestDetails