import React from "react";

import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import domainName from "../../domainName";

import { SHA256 } from "crypto-js";

import GoogleButton from "react-google-button";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateToken } from "../../redux/slices/login";
import { logInSuccess } from "../../redux/slices/login";
import { insertAnonymousUserId } from "../../redux/slices/login";

import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { COLORS, SIZES, FONTS } from "../../colorTheme/colorTheme";
import EnterVerificationCode from "./EnterVerificationCode";
import { logOut } from "../../redux/slices/login";

import { useTranslation } from "react-i18next";


const Signup = () => {

    const [t] = useTranslation("global");

    const smsKey = process.env.REACT_APP_SMS_SECRET_KEY;
    const apiKey = process.env.REACT_APP_API_KEY;

    const location = useLocation();
    const { redirect } = queryString.parse(location.search);

    const { token, UserData, UserId } = useSelector((state) => state.login);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // for otp count down
    const [isRequestingOTP, setIsRequestingOTP] = useState(false);
    const cooldownDuration = 30; // cooldown period in seconds

    // for password visible
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [email, setEmail] = useState("");
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [mobileNumber, setMobileNumber] = useState('');
    const [tabSelection, setTabSelection] = useState(true); // true for mobile, false for email

    //red words warning 
    const [nameLabelRedWords, setNameLabelRedWords] = useState('')
    const [mobileLabelRedWords, setMobileLabelRedWords] = useState('')
    const [emailLabelRedWords, setEmailLabelRedWords] = useState('')
    const [confirmPasswordRedWords, setConfirmPasswordRedWords] = useState('')
    const [passwordRedWords, setPasswordRedWords] = useState('')

    const [frontCountryPhoneNumber, setFrontCountryPhoneNumber] = useState('+60');

    const [referralcode, setReferralcode] = useState()


    // for country code selector 
    const countryData = [
        { code: '+93', name: 'Afghanistan' },
        { code: '+355', name: 'Albania' },
        { code: '+213', name: 'Algeria' },
        { code: '+1-684', name: 'American Samoa' },
        { code: '+376', name: 'Andorra' },
        { code: '+244', name: 'Angola' },
        { code: '+1-264', name: 'Anguilla' },
        { code: '+672', name: 'Antarctica' },
        { code: '+1-268', name: 'Antigua and Barbuda' },
        { code: '+54', name: 'Argentina' },
        { code: '+374', name: 'Armenia' },
        { code: '+297', name: 'Aruba' },
        { code: '+61', name: 'Australia' },
        { code: '+43', name: 'Austria' },
        { code: '+994', name: 'Azerbaijan' },
        { code: '+1-242', name: 'Bahamas' },
        { code: '+973', name: 'Bahrain' },
        { code: '+880', name: 'Bangladesh' },
        { code: '+1-246', name: 'Barbados' },
        { code: '+375', name: 'Belarus' },
        { code: '+32', name: 'Belgium' },
        { code: '+501', name: 'Belize' },
        { code: '+229', name: 'Benin' },
        { code: '+1-441', name: 'Bermuda' },
        { code: '+975', name: 'Bhutan' },
        { code: '+591', name: 'Bolivia' },
        { code: '+387', name: 'Bosnia and Herzegovina' },
        { code: '+267', name: 'Botswana' },
        { code: '+55', name: 'Brazil' },
        { code: '+246', name: 'British Indian Ocean Territory' },
        { code: '+1-284', name: 'British Virgin Islands' },
        { code: '+673', name: 'Brunei' },
        { code: '+359', name: 'Bulgaria' },
        { code: '+226', name: 'Burkina Faso' },
        { code: '+257', name: 'Burundi' },
        { code: '+855', name: 'Cambodia' },
        { code: '+237', name: 'Cameroon' },
        { code: '+1', name: 'Canada' },
        { code: '+238', name: 'Cape Verde' },
        { code: '+1-345', name: 'Cayman Islands' },
        { code: '+236', name: 'Central African Republic' },
        { code: '+235', name: 'Chad' },
        { code: '+56', name: 'Chile' },
        { code: '+86', name: 'China' },
        { code: '+61', name: 'Christmas Island' },
        { code: '+61', name: 'Cocos Islands' },
        { code: '+57', name: 'Colombia' },
        { code: '+269', name: 'Comoros' },
        { code: '+682', name: 'Cook Islands' },
        { code: '+506', name: 'Costa Rica' },
        { code: '+385', name: 'Croatia' },
        { code: '+53', name: 'Cuba' },
        { code: '+599', name: 'Curacao' },
        { code: '+357', name: 'Cyprus' },
        { code: '+420', name: 'Czech Republic' },
        { code: '+243', name: 'Democratic Republic of the Congo' },
        { code: '+45', name: 'Denmark' },
        { code: '+253', name: 'Djibouti' },
        { code: '+1-767', name: 'Dominica' },
        { code: '+1-809, +1-829, +1-849', name: 'Dominican Republic' },
        { code: '+670', name: 'East Timor' },
        { code: '+593', name: 'Ecuador' },
        { code: '+20', name: 'Egypt' },
        { code: '+503', name: 'El Salvador' },
        { code: '+240', name: 'Equatorial Guinea' },
        { code: '+291', name: 'Eritrea' },
        { code: '+372', name: 'Estonia' },
        { code: '+251', name: 'Ethiopia' },
        { code: '+500', name: 'Falkland Islands' },
        { code: '+298', name: 'Faroe Islands' },
        { code: '+679', name: 'Fiji' },
        { code: '+358', name: 'Finland' },
        { code: '+33', name: 'France' },
        { code: '+689', name: 'French Polynesia' },
        { code: '+241', name: 'Gabon' },
        { code: '+220', name: 'Gambia' },
        { code: '+995', name: 'Georgia' },
        { code: '+49', name: 'Germany' },
        { code: '+233', name: 'Ghana' },
        { code: '+350', name: 'Gibraltar' },
        { code: '+30', name: 'Greece' },
        { code: '+299', name: 'Greenland' },
        { code: '+1-473', name: 'Grenada' },
        { code: '+1-671', name: 'Guam' },
        { code: '+502', name: 'Guatemala' },
        { code: '+44-1481', name: 'Guernsey' },
        { code: '+224', name: 'Guinea' },
        { code: '+245', name: 'Guinea-Bissau' },
        { code: '+592', name: 'Guyana' },
        { code: '+509', name: 'Haiti' },
        { code: '+504', name: 'Honduras' },
        { code: '+852', name: 'Hong Kong' },
        { code: '+36', name: 'Hungary' },
        { code: '+354', name: 'Iceland' },
        { code: '+91', name: 'India' },
        { code: '+62', name: 'Indonesia' },
        { code: '+98', name: 'Iran' },
        { code: '+964', name: 'Iraq' },
        { code: '+353', name: 'Ireland' },
        { code: '+44-1624', name: 'Isle of Man' },
        { code: '+972', name: 'Israel' },
        { code: '+39', name: 'Italy' },
        { code: '+225', name: 'Ivory Coast' },
        { code: '+1-876', name: 'Jamaica' },
        { code: '+81', name: 'Japan' },
        { code: '+44-1534', name: 'Jersey' },
        { code: '+962', name: 'Jordan' },
        { code: '+7', name: 'Kazakhstan' },
        { code: '+254', name: 'Kenya' },
        { code: '+686', name: 'Kiribati' },
        { code: '+383', name: 'Kosovo' },
        { code: '+965', name: 'Kuwait' },
        { code: '+996', name: 'Kyrgyzstan' },
        { code: '+856', name: 'Laos' },
        { code: '+371', name: 'Latvia' },
        { code: '+961', name: 'Lebanon' },
        { code: '+266', name: 'Lesotho' },
        { code: '+231', name: 'Liberia' },
        { code: '+218', name: 'Libya' },
        { code: '+423', name: 'Liechtenstein' },
        { code: '+370', name: 'Lithuania' },
        { code: '+352', name: 'Luxembourg' },
        { code: '+853', name: 'Macao' },
        { code: '+389', name: 'Macedonia' },
        { code: '+261', name: 'Madagascar' },
        { code: '+265', name: 'Malawi' },
        { code: '+60', name: 'Malaysia' },
        { code: '+960', name: 'Maldives' },
        { code: '+223', name: 'Mali' },
        { code: '+356', name: 'Malta' },
        { code: '+692', name: 'Marshall Islands' },
        { code: '+222', name: 'Mauritania' },
        { code: '+230', name: 'Mauritius' },
        { code: '+262', name: 'Mayotte' },
        { code: '+52', name: 'Mexico' },
        { code: '+691', name: 'Micronesia' },
        { code: '+373', name: 'Moldova' },
        { code: '+377', name: 'Monaco' },
        { code: '+976', name: 'Mongolia' },
        { code: '+382', name: 'Montenegro' },
        { code: '+1-664', name: 'Montserrat' },
        { code: '+212', name: 'Morocco' },
        { code: '+258', name: 'Mozambique' },
        { code: '+95', name: 'Myanmar' },
        { code: '+264', name: 'Namibia' },
        { code: '+674', name: 'Nauru' },
        { code: '+977', name: 'Nepal' },
        { code: '+31', name: 'Netherlands' },
        { code: '+599', name: 'Netherlands Antilles' },
        { code: '+687', name: 'New Caledonia' },
        { code: '+64', name: 'New Zealand' },
        { code: '+505', name: 'Nicaragua' },
        { code: '+227', name: 'Niger' },
        { code: '+234', name: 'Nigeria' },
        { code: '+683', name: 'Niue' },
        { code: '+850', name: 'North Korea' },
        { code: '+1-670', name: 'Northern Mariana Islands' },
        { code: '+47', name: 'Norway' },
        { code: '+968', name: 'Oman' },
        { code: '+92', name: 'Pakistan' },
        { code: '+680', name: 'Palau' },
        { code: '+970', name: 'Palestine' },
        { code: '+507', name: 'Panama' },
        { code: '+675', name: 'Papua New Guinea' },
        { code: '+595', name: 'Paraguay' },
        { code: '+51', name: 'Peru' },
        { code: '+63', name: 'Philippines' },
        { code: '+870', name: 'Pitcairn' },
        { code: '+48', name: 'Poland' },
        { code: '+351', name: 'Portugal' },
        { code: '+1-787, +1-939', name: 'Puerto Rico' },
        { code: '+974', name: 'Qatar' },
        { code: '+242', name: 'Republic of the Congo' },
        { code: '+262', name: 'Reunion' },
        { code: '+40', name: 'Romania' },
        { code: '+7', name: 'Russia' },
        { code: '+250', name: 'Rwanda' },
        { code: '+590', name: 'Saint Barthelemy' },
        { code: '+290', name: 'Saint Helena' },
        { code: '+1-869', name: 'Saint Kitts and Nevis' },
        { code: '+1-758', name: 'Saint Lucia' },
        { code: '+590', name: 'Saint Martin' },
        { code: '+508', name: 'Saint Pierre and Miquelon' },
        { code: '+1-784', name: 'Saint Vincent and the Grenadines' },
        { code: '+685', name: 'Samoa' },
        { code: '+378', name: 'San Marino' },
        { code: '+239', name: 'Sao Tome and Principe' },
        { code: '+966', name: 'Saudi Arabia' },
        { code: '+221', name: 'Senegal' },
        { code: '+381', name: 'Serbia' },
        { code: '+248', name: 'Seychelles' },
        { code: '+232', name: 'Sierra Leone' },
        { code: '+65', name: 'Singapore' },
        { code: '+1-721', name: 'Sint Maarten' },
        { code: '+421', name: 'Slovakia' },
        { code: '+386', name: 'Slovenia' },
        { code: '+677', name: 'Solomon Islands' },
        { code: '+252', name: 'Somalia' },
        { code: '+27', name: 'South Africa' },
        { code: '+82', name: 'South Korea' },
        { code: '+211', name: 'South Sudan' },
        { code: '+34', name: 'Spain' },
        { code: '+94', name: 'Sri Lanka' },
        { code: '+249', name: 'Sudan' },
        { code: '+597', name: 'Suriname' },
        { code: '+47', name: 'Svalbard and Jan Mayen' },
        { code: '+268', name: 'Swaziland' },
        { code: '+46', name: 'Sweden' },
        { code: '+41', name: 'Switzerland' },
        { code: '+963', name: 'Syria' },
        { code: '+886', name: 'Taiwan' },
        { code: '+992', name: 'Tajikistan' },
        { code: '+255', name: 'Tanzania' },
        { code: '+66', name: 'Thailand' },
        { code: '+228', name: 'Togo' },
        { code: '+690', name: 'Tokelau' },
        { code: '+676', name: 'Tonga' },
        { code: '+1-868', name: 'Trinidad and Tobago' },
        { code: '+216', name: 'Tunisia' },
        { code: '+90', name: 'Turkey' },
        { code: '+993', name: 'Turkmenistan' },
        { code: '+1-649', name: 'Turks and Caicos Islands' },
        { code: '+688', name: 'Tuvalu' },
        { code: '+1-340', name: 'U.S. Virgin Islands' },
        { code: '+256', name: 'Uganda' },
        { code: '+380', name: 'Ukraine' },
        { code: '+971', name: 'United Arab Emirates' },
        { code: '+44', name: 'United Kingdom' },
        { code: '+1', name: 'United States' },
        { code: '+598', name: 'Uruguay' },
        { code: '+998', name: 'Uzbekistan' },
        { code: '+678', name: 'Vanuatu' },
        { code: '+379', name: 'Vatican' },
        { code: '+58', name: 'Venezuela' },
        { code: '+84', name: 'Vietnam' },
        { code: '+681', name: 'Wallis and Futuna' },
        { code: '+212', name: 'Western Sahara' },
        { code: '+967', name: 'Yemen' },
        { code: '+260', name: 'Zambia' },
        { code: '+263', name: 'Zimbabwe' },
    ];
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    //allow only digit input in phone input textbox
    const handlePhoneInput = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9]*$/;

        if (regex.test(inputValue)) {
            if (frontCountryPhoneNumber == '+60' && inputValue.startsWith('0')) { // if Malaysia phone number only
                // Remove the leading '0'
                setMobileNumber(inputValue.slice(1));
            } else {
                // If the input doesn't start with '0', set the number input directly
                setMobileNumber(inputValue);
            }
        }else{
            setMobileNumber('');
        }
    }

    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
        // //console.log(event.target.value)
    };


    const handleTabSelection = (isMobileTab) => {
        setTabSelection(isMobileTab);
    };

    //check password and confirmation password is the same
    const checkSamePassword = async () => {
        // here need to add one more function which is check the password is empty 
        if (password == confirmPassword) {
            return true
        } else {
            return false
        }
    }

    //check password and confirmation password is the same
    const checkPasswordLength = async () => {
        // here need to add one more function which is check the password's length
        if (password.length < 8) {
            return false;
        } else {
            return true
        }

    }

    const processCheckMobileNumberValid = async () => {
        // here need to do validation , if the user go next label, then call APi to check this mobile no is whether valid
        const dataCode = await checkMobileNumberValid()


        //console.log('dataCode.Data', dataCode.Data)
        if (dataCode.Data != null) {
            // means existed, need to show red words
            setMobileLabelRedWords(t('errorPush.MobileNo had already existed, please try another.'))

            return false;
        } else {
            // means not existed, show nothings
            setMobileLabelRedWords('')

            return true;
        }
    }

    const processCheckEmailValid = async () => {
        // here need to do validation , if the user go next label, then call APi to check this Email no is whether valid
        const dataCode = await checkEmailValid()


        //console.log('dataCode.Data', dataCode.Data)
        if (dataCode.Data != null) {
            // means existed, need to show red words
            setEmailLabelRedWords(t('errorPush.Email had already existed, please try another.'))

            return false;
        } else {
            // means not existed, show nothings
            setEmailLabelRedWords('')

            return true;
        }
    }

    const checkMobileNumberValid = async () => {
        let returnResponseData;

        //console.log('checkMobileNumberValid')

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "MobileNo": frontCountryPhoneNumber + mobileNumber,
                    "LoginType": "MOBILE_NO"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log("/user/checkingMobileNoOrEmailIsExisting responseJson > ", responseJson);

                returnResponseData = responseJson;



            }))
            .catch((error) => {
                //console.log("/user/checkingMobileNoOrEmailIsExisting error > ", error);
            });

        return returnResponseData
    }

    const checkEmailValid = async () => {
        let returnResponseData;

        //console.log('checkEmailNumberValid')

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "Email": email,
                    "LoginType": "EMAIL"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                //console.log("/user/checkingMobileNoOrEmailIsExisting responseJson > ", responseJson);
                returnResponseData = responseJson;
            }))
            .catch((error) => {
                //console.log("/user/checkingMobileNoOrEmailIsExisting error > ", error);
            });

        return returnResponseData
    }




    const handleFormSubmit = async (event) => {
        event.preventDefault(); // prevent default form submission behavior
        // do something with email and password data (e.g. send to server for authentication)

        let errors = [];

        // here to do validation 
        const passwordSame = await checkSamePassword()
        const passwordLengthMorethan8 = await checkPasswordLength()

        if (tabSelection) {
            const returnBoolean = await processCheckMobileNumberValid()
            if (!returnBoolean) {
                errors.push(t("errorPush.- Mobile No had already existed, please try another!"));
            }
        } else {
            const returnBoolean = await processCheckEmailValid()
            if (!returnBoolean) {
                errors.push(t("errorPush.- Email had already existed, please try another!"));
            }
        }


        if (!passwordLengthMorethan8) {
            errors.push(t("errorPush.- Password must be at least 8 characters!"));
            setPasswordRedWords(t("errorPush.Password must be at least 8 characters."))
        } else {
            setPasswordRedWords("")
        }

        if (!passwordSame) {
            errors.push(t("errorPush.- Password must be the same as confirmation password!"));
            setConfirmPasswordRedWords(t("errorPush.Password must be the same as confirmation password."))
        } else {
            setConfirmPasswordRedWords("")
        }


        if (username == "") {
            errors.push(t("errorPush.- Name cannot be empty!"));
            setNameLabelRedWords(t("errorPush.Name cannot be empty."))
        } else {
            setNameLabelRedWords("")
        }

        // Display errors in alert
        if (errors.length > 0) {
            // alert("Oops!" + "\n" + errors.join("\n"));
            //console.log("errors : ", errors)
        } else {
            // Proceed with sign up
            // //console.log("email and password ->", email, password)
            // var passwordHash = SHA256(password).toString();

            //console.log("tab selection here > ", tabSelection)

            if (tabSelection) {
                //phone sign up
                if (!isRequestingOTP) {

                    setIsRequestingOTP(true);
                    // Proceed with OTP request
                    await signUpWithPhone()

                    setTimeout(() => {
                        setIsRequestingOTP(false);
                    }, cooldownDuration * 1000);

                } else {
                    alert(t('error.Oops! Please wait 30 seconds before requesting OTP again.'))
                }
            } else {
                // email sign up
                await signUpWithEmail()
            }
        }

    };

    async function signUpWithPhone() {
        const smsid = SHA256(frontCountryPhoneNumber + mobileNumber + "Adviser2u" + apiKey + smsKey)
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/sendTwilioOtp",
                "POST",
                JSON.stringify({
                    "MobileNo": frontCountryPhoneNumber + mobileNumber,
                    "SMS_ID": smsid.toString()
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/sendTwilioOtp responseJson here', responseJson)
                if (responseJson.ReturnCode == 200) {
                    navigate(`/enterverificationcode?${queryString.stringify({
                        phoneNumber: frontCountryPhoneNumber + mobileNumber,
                        password: SHA256(password).toString(),
                        username: username,
                        referalcode: referralcode
                    })}`)
                } else {
                    alert(t('error.Send otp got error, may contact administrator'))
                }
            }))
            .catch((error) => {
                //console.log('/user/sendTwilioOtp error here', error)
            });
    }

    async function signUpWithEmail() {
        //console.log('go sign up with email')
        const passwordHashed = SHA256(confirmPassword).toString();
        const diversifyKey = Math.random().toString(36).substring(2, 12);

        // //console.log('insertUser :', loginType, passwordHashed, diversifyKey, userName)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateAnonymouseUserToUserWithEmailAndMobileNo",
                "POST",
                JSON.stringify({
                    "Email": email,
                    "MobileNo": null,
                    "LoginType": 'EMAIL',
                    "Password": passwordHashed,
                    "DiversifyKey": diversifyKey,
                    "UserName": username,
                    "FirstRegisterReferralCode": referralcode ? referralcode : null
                }),
                UserId // this one will keep unless user login and logout, so we need to clear the cache at below (perform logout action)
            )
            .then(domainName.handleServerAPICallErrors)
            .then((async responseJson => {

                //return data here
                //console.log("/user/updateAnonymouseUserToUserWithEmailAndMobileNo > responseJson", responseJson);

                if (responseJson.ReturnCode == 200) {

                    await insertAnonymouseUser()
                    navigate(`/popuserverifyemail?${queryString.stringify({
                        email: email
                    })}`)
                } else {
                    if (responseJson.ReturnDesc) {
                        alert(responseJson.ReturnDesc)
                    } else {
                        alert(t("error.Something went wrong. Please contact your technical staff."))
                    }
                }

            }))
            .catch((error) => {
                //console.log("/user/updateAnonymouseUserToUserWithEmailAndMobileNo > error", error);
            });
    }

    async function insertAnonymouseUser() {
        //do insert anonymous hereeee
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/insertAnonymouseUser",
                "POST",
                // JSON.stringify({
                // })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log('responseJson in /user/insertAnonymouseUser', responseJson);

                //here to do dispatch(AutoLogin .. something)
                if (responseJson.Data != null) {
                    dispatch(logOut());
                    dispatch(insertAnonymousUserId(responseJson.Data._id))
                } else {
                    alert(responseJson.ReturnDesc);
                }
                // anonymous still remain true

            }))
            .catch((error) => {
                alert(t("error.An error occurred. Please try again later."));
            });
    }

    const { googleSignIn, facebookSignIn, appleSignIn, user } = UserAuth();
    // const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn()

        } catch (error) {
            //console.log(error)
        }
    }

    const handleFacebookSignIn = async () => {
        try {
            await facebookSignIn()

        } catch (error) {
            //console.log(error)
        }
    }

    const handleAppleSignIn = async () => {
        try {
            await appleSignIn()

        } catch (error) {
            //console.log(error)
        }
    }

    // useEffect(() => {
    //     if (user != null) {
    //         navigate('/account')
    //     }
    // }, [user])

    useEffect(() => {
        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const reCode = searchParams.get('referralCode');
        setReferralcode(reCode)

    }, [])

    return (
        <div className="greybk-div p-md-5">
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-6">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('signInSignUp.Sign up')}</h5>
                                </Col>
                            </Row>

                            <Row className="mt-3 mb-4">
                                <Col>
                                    <ul className="nav nav-tabs ">
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${tabSelection ? 'active' : ''}`}
                                                onClick={() => handleTabSelection(true)}
                                                style={{ color: tabSelection ? COLORS.orange : COLORS.gray }}
                                            >
                                               {t('signInSignUp.Mobile')}
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${!tabSelection ? 'active' : ''}`}
                                                onClick={() => handleTabSelection(false)}
                                                style={{ color: !tabSelection ? COLORS.orange : COLORS.gray }}
                                            >
                                                {t('signInSignUp.Email')}
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <form onSubmit={handleFormSubmit}>

                                        <div>
                                            <label htmlFor="username">{t('signInSignUp.Name')}</label>
                                            <div className="input-group mt-2">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    value={username}
                                                    onChange={handleUserNameChange}
                                                    required
                                                />
                                            </div>
                                            <span style={{ color: COLORS.red }}>{nameLabelRedWords}</span>
                                        </div>


                                        {tabSelection ? (
                                            <div className="mt-2">
                                                <label htmlFor="mobileNumber">{t('signInSignUp.Mobile No:')}</label>

                                                <Row>
                                                    <Col className="col-4">
                                                        <Dropdown className="mt-2">
                                                            <Dropdown.Toggle className="form-control" variant="light" id="countryDropdown" style={{ backgroundColor: COLORS.white, borderColor: COLORS.lightGray1 }}>
                                                                {frontCountryPhoneNumber}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu alignRight>
                                                                {/* <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+60")}>+60</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+65")}>+65</Dropdown.Item> */}

                                                                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>=
                                                                    {countryData.map((country, index) => (
                                                                        <Dropdown.Item key={index} onClick={() => setFrontCountryPhoneNumber(country.code)}>
                                                                            {country.name} ({country.code})
                                                                        </Dropdown.Item>
                                                                    ))}
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                    <Col className="col-8">
                                                        <div className="input-group mt-2">
                                                            <input
                                                                className="form-control"
                                                                type="tel"
                                                                id="mobileNumber"
                                                                name="mobileNumber"
                                                                value={mobileNumber}
                                                                onChange={handlePhoneInput}
                                                                required
                                                            />
                                                        </div>

                                                    </Col>

                                                </Row>


                                                <span style={{ color: COLORS.red }}>{mobileLabelRedWords}</span>
                                            </div>
                                        ) : (
                                            <div className="mt-2">
                                                <label htmlFor="email">{t('signInSignUp.Email')}:</label>
                                                <div className="input-group mt-2">
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={handleEmailChange}
                                                        required
                                                    />
                                                </div>
                                                <span style={{ color: COLORS.red }}>{emailLabelRedWords}</span>
                                            </div>
                                        )}

                                        <div className="mt-3">
                                            <label htmlFor="password">{t('signInSignUp.Password')}:</label>
                                            <div className="form-control" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <input
                                                    className="form-control shadow-none"
                                                    type={showPassword ? 'text' : 'password'}
                                                    id="password"
                                                    name="password"
                                                    value={password}
                                                    style={{ paddingLeft: 0, marginLeft: 0, borderWidth: 0 }}
                                                    onChange={handlePasswordChange}
                                                    required
                                                />
                                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}>
                                                    {
                                                        showPassword ?
                                                            <img
                                                                src={(require('../../img/icons/showpassword.png'))}
                                                                style={{
                                                                    height: 22, width: 22
                                                                }}
                                                            />
                                                            :
                                                            <img
                                                                src={(require('../../img/icons/hidepassword.png'))}
                                                                style={{
                                                                    height: 18, width: 18
                                                                }}
                                                            />
                                                    }

                                                </div>
                                            </div>
                                            <span style={{ color: COLORS.red }}>{passwordRedWords}</span>
                                        </div>

                                        <div className="mt-3">
                                            <label htmlFor="password">{t('signInSignUp.Confirm Password')}:</label>
                                            <div className="form-control" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <input
                                                    className="form-control shadow-none"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    id="password"
                                                    name="password"
                                                    value={confirmPassword}
                                                    style={{ paddingLeft: 0, marginLeft: 0, borderWidth: 0 }}
                                                    onChange={handleConfirmPasswordChange}
                                                    required
                                                />
                                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                                    setShowConfirmPassword(!showConfirmPassword)
                                                }}>
                                                    {
                                                        showConfirmPassword ?
                                                            <img
                                                                src={(require('../../img/icons/showpassword.png'))}
                                                                style={{
                                                                    height: 22, width: 22
                                                                }}
                                                            />
                                                            :
                                                            <img
                                                                src={(require('../../img/icons/hidepassword.png'))}
                                                                style={{
                                                                    height: 18, width: 18
                                                                }}
                                                            />
                                                    }

                                                </div>
                                            </div>
                                            <span style={{ color: COLORS.red }}>{confirmPasswordRedWords}</span>
                                        </div>

                                        <div className="mt-3">
                                            <label htmlFor="password">{t('signInSignUp.Referral Code')}:</label>
                                            <div className="form-control" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <input
                                                    className="form-control shadow-none"
                                                    type='text'
                                                    id="referralcode"
                                                    name="referralcode"
                                                    value={referralcode}
                                                    style={{ paddingLeft: 0, marginLeft: 0, borderWidth: 0 }}
                                                    onChange={(e) => {
                                                        // console.log("e.target.value", e.target.value)
                                                        setReferralcode(e.target.value)
                                                    }}
                                                // required
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="mt-5">
                                            <Row >
                                                <Col>
                                                    <div className='' style={{'text-align': 'right'}}>Forgot Password</div>
                                                </Col>
                                            </Row>
                                        </div> */}


                                        <div className="mt-5">
                                            <Row className="justify-content-center align-items-center">
                                                <Col>
                                                    <Button className='orange-btn text-center' type="submit" >{t('signInSignUp.Sign up')}</Button>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="mt-5">

                                            <Row>
                                                <Col>
                                                    <div className='' style={{ textAlign: 'center' }}>{t('signInSignUp.Have an account?')}
                                                        <Link style={{ textDecorationLine: 'none' }} to="/signin">
                                                            <span style={{ color: COLORS.orange, textDecoration: 'underline' }}> {t('signInSignUp.Log In')}</span>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>

                                        <div className="mt-5">
                                            <Row className="justify-content-center">
                                                <Col xs={4} sm={4} className="text-center">
                                                    <div style={{ cursor: 'pointer' }} onClick={handleFacebookSignIn}>
                                                        <img src={require('../../img/icons/fb.png')} style={{ width: 50, height: 50 }} alt="Facebook Icon" />
                                                    </div>
                                                    {/* <Button onClick={handleFacebookSignIn}>Facebook log in</Button> */}
                                                </Col>

                                                <Col xs={4} sm={4} className="text-center">
                                                    <div style={{ cursor: 'pointer' }} onClick={handleGoogleSignIn}>
                                                        <img src={require('../../img/icons/googleicon.png')} style={{ width: 50, height: 50 }} alt="Google Icon" />
                                                    </div>
                                                    {/* <GoogleButton onClick={handleGoogleSignIn} /> */}
                                                </Col>

                                                <Col xs={4} sm={4} className="text-center">
                                                    <div style={{ cursor: 'pointer' }} onClick={handleAppleSignIn}>
                                                        <img src={require('../../img/icons/applelogo.png')} style={{ width: 50, height: 50 }} alt="Apple Icon" />
                                                    </div>
                                                    {/* <Button onClick={handleAppleSignIn}>Apple log in</Button> */}
                                                </Col>
                                            </Row>
                                        </div>


                                    </form>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default Signup