import { useContext, createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import domainName from "../domainName";
import { logInSuccess, insertAnonymousUserId, setProfileImage } from "../redux/slices/login";
import { useNavigate } from "react-router-dom";

import {
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    signOut,
    onAuthStateChanged,
    FacebookAuthProvider,
    OAuthProvider
} from "firebase/auth";

import firebase from "firebase/app";

import { auth } from "../firebase";

import queryString from 'query-string';
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const [user, setUser] = useState({})
    const dispatch = useDispatch();
    const { token, UserData, UserId } = useSelector((state) => state.login);

    const navigate = useNavigate();
    const location = useLocation();
    const { redirect } = queryString.parse(location.search);

    const [t, i18n] = useTranslation("global");


    const thirdpartyLoginInsertUser = async (email, appType, userName, uid, phoneNumber) => {

        // let returncode;

        // console.log('thirdpartyLoginInsertUser /user/updateAnonymouseUserToUserWithThirdPartyLogin', email, appType, userName, uid, phoneNumber, UserId)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateAnonymouseUserToUserWithThirdPartyLogin",
                "POST",
                JSON.stringify({
                    "Email": email,
                    "AppType": appType,
                    "UserName": userName ? userName : email.split('@')[0],
                    "MobileNo": phoneNumber,
                    "AppId": uid,
                    "isNoEmail": appType == "FACEBOOK" ? true : false // since now facebook not yet do reviewing for email request access, so this is the temporary solution
                }),
                UserId
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                // returncode = responseJson.ReturnCode;
                //return data here
                // console.log('thirdpartyLoginInsertUser responseJson here', responseJson);

                if (responseJson.ReturnCode == 200) {
                    //since google sign in done and insert user data into our db successful, so direct user to home page.
                    //dispatch login successful here
                    // console.log('responseJson.ReturnCode == 200, dispatch login success here')
                    dispatch(logInSuccess(responseJson.Data));
                    dispatch(insertAnonymousUserId(responseJson.Data._id))     
                    navigate(redirect || "/");

                } else {
                    alert( t("error.Google successful signed in but error when inserting to db, pls check here."));
                }

            }))
            .catch((error) => {
                alert( t("error.An error occurred. Please try again later."));
            });


    }


    const thirdPartyLogin = async (email, appType, userName, uid, phoneNumber) => {
        // console.log(domainName.debugAPI);
        // console.log('thirdPartyLogin - Pass in these data', email, appType, userName, uid);

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userLoginByThirdPartyAccount",
                "POST",
                JSON.stringify({
                    "LoginType": appType,
                    "ThirdPartyTokenID": uid //since token id return from google is not consistency, so we use uid instead of tokenId
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                // console.log('thirdPartyLogin responseJson here', responseJson);
                if (responseJson.ReturnCode == 404) {

                    // console.log('(responseJson.ReturnCode == 404)')

                    thirdpartyLoginInsertUser(email, appType, userName, uid, phoneNumber)

                } else if (responseJson.ReturnCode == 200) {

                    // console.log('(responseJson.ReturnCode == 200)')
                    // console.log('dispatch login successful here -> data', responseJson.Data)
                    //dispatch blabla
                    dispatch(logInSuccess(responseJson.Data));
                    dispatch(insertAnonymousUserId(responseJson.Data._id))

                    navigate(redirect || "/");
                    //  navigation.navigate('Home', {})

                }


            }))
            .catch((error) => {
                alert( t("error.An error occurred. Please try again later."))
            });
    }

    const appleSignIn = () => {
        const provider = new OAuthProvider('apple.com');
        // provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('name');

        // console.log("provider here", provider)

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;

                // The signed-in user info.
                const user = result.user;
                // console.log("user info from apple", user)

                await thirdPartyLogin(user.email, 'APPLE', user.displayName, user.uid, user.phoneNumber)

            }).catch((error) => {

                // console.log('apple login error :', error)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = OAuthProvider.credentialFromError(error);
                // ...
            });
    }


    const facebookSignIn = () => {
        const provider = new FacebookAuthProvider();
        // console.log("(auth, provider)", auth, provider)
        // provider.addScope('profile');
        // provider.addScope('email');

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = FacebookAuthProvider.credentialFromResult(result);

                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // console.log("user info from facebook", user)

                await thirdPartyLogin(user.uid, 'FACEBOOK', user.displayName, user.uid, user.phoneNumber)

            }).catch((error) => {
                alert(t(`error.facebook login error`))
                // console.log('facebook login error :', error)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);
                // ...
            });
    }



    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        // console.log("(auth, provider)", auth, provider)
        provider.addScope('profile');
        provider.addScope('email');

        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);

                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // console.log("user", user)

                await thirdPartyLogin(user.email, 'GOOGLE', user.displayName, user.uid, user.phoneNumber)

            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    const logOutThirdParty = () => {
        signOut(auth)
    }

    useEffect(() => {
        const unsubsribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            // // console.log("User", currentUser)
        })
        return () => {
            unsubsribe()
        }
    }, [])

    return (
        <AuthContext.Provider value={{ googleSignIn, facebookSignIn, appleSignIn, logOutThirdParty, user }}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext)
}