

export const COLORS = {
    primary: "#FF6C44", //orange
    transparentPrimray: 'rgba(227, 120, 75, 0.4)',
    orange: "#C0281C",
    lightOrange:"#FAEDEC",
    lightOrange1: "#FF9B00",
    lightOrange2: "#F19B39",
    lightOrange3: '#FFD9AD',
    green: "#27AE60",
    red: "#FF1717",
    blue: '#0064C0',
    darkBlue: "#111A2C",
    darkGray: "#101223",
    darkGray2: "#757D85",
    gray: "#5B5D6B",
    gray2: "#BBBDC1",
    gray3: '#CFD0D7',
    lightGray1: "#D9D9D9",
    lightGray2: "#F5F5F8",
    lightGray3:"#F6F6F6",
    lightGray4:"#F8F8F8",
    lightGrayAccount: '#F4F4F4',
    white2: "#FFFFFFF0",
    white: '#FFFFFF',
    black: "#000000",
    yellow:'#F9F525',

    transparent: 'transparent',
    transparentBlack1: "rgba(0, 0, 0, 0.1)",
    transparentBlack7: "rgba(0, 0, 0, 0.7)"

};
export const SIZES = {
    // global sizes
    base: 8,
    font: 14,
    radius: 12,
    padding: 24,
    

    // font sizes
    largeTitle: 40,
    h1: 30,
    h2: 22,
    h3: 16,
    h4: 14,
    h5: 12,
    body1: 30,
    body2: 22,
    body3: 16,
    body4: 14,
    body5: 12,

    commonSize:20

};
export const FONTS = {
    largeTitle: { fontFamily: "OpenSans-Regular", fontSize: SIZES.largeTitle },
    h1: { fontFamily: "OpenSans-Bold", fontSize: SIZES.h1, lineHeight: 36 },
    h2: { fontFamily: "OpenSans-Bold", fontSize: SIZES.h2, lineHeight: 30, },
    h3: { fontFamily: "OpenSans-SemiBold", fontSize: SIZES.h3, lineHeight: 22 },
    h4: { fontFamily: "OpenSans-SemiBold", fontSize: SIZES.h4, lineHeight: 22 },
    h5: { fontFamily: "OpenSans-SemiBold", fontSize: SIZES.h5, lineHeight: 22 },
    body1: { fontFamily: "OpenSans-Regular", fontSize: SIZES.body1, lineHeight: 36 },
    body2: { fontFamily: "OpenSans-Regular", fontSize: SIZES.body2, lineHeight: 30 },
    body3: { fontFamily: "OpenSans-Regular", fontSize: SIZES.body3, lineHeight: 22 },
    body4: { fontFamily: "OpenSans-Regular", fontSize: SIZES.body4, lineHeight: 22 },
    body5: { fontFamily: "OpenSans-Regular", fontSize: SIZES.body5, lineHeight: 22 },
};

const appTheme = { COLORS, SIZES, FONTS };

export default appTheme;


