import React from 'react'
import { Container, Row, Col, Image, Button, Card, Modal, Dropdown } from 'react-bootstrap';
import domainName from '../../domainName';
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme';
import { useEffect, useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { SHA256 } from "crypto-js";
import { setUserId } from 'firebase/analytics';
import { useSelector } from 'react-redux';
// import navigation component
import Navigation from '../../components/Navigation';
import { useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';
import './community.css';


import { useTranslation } from "react-i18next";


const Community = () => {
    const { pathname, search } = useLocation();
    const [showCreatePostSection, setShowCreatePostSection] = useState(false);
    const { UserId } = useSelector((state) => state.login);
    const navigate = useNavigate();
    const userAgent = navigator.userAgent;
    const [isLikeHovered, setIsLikeHovered] = useState(false);
    const [isCommentHovered, setIsCommentHovered] = useState(false);
    const [isShareHovered, setIsShareHovered] = useState(false);
    //for navigation bar visibility
    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [pathLang, setPathLang] = useState('');
    const [isFromAppUser, setIsFromAppUser] = useState(false);

    //for image sizing
    const [view, setView] = useState('');

    const [userIdForCommunity, setUserIdForCommunity] = useState()
    const [userIdValid, setUserIdValid] = useState(false)

    //feeds new
    const [isFeed, setIsFeed] = useState(true)

    //for community listing
    const [feedListings, setFeedListings] = useState([])
    const [newListings, setNewListings] = useState([])

    // for create post modal
    const [showCreatePostModal, setShowCreatePostModal] = useState();

    // for edit post modal
    const [showEditPostModal, setShowEditPostModal] = useState();
    const [editItem, setEditItem] = useState();

    //for delete post modal
    const [showDeletePostModal, setShowDeletePostModal] = useState(false)
    const [deleteItem, setDeleteItem] = useState()

    // for loading process upload create post
    const [loading, setLoading] = useState(false)

    const maxCommentLength = 100;
    const [expanded, setExpanded] = useState(false);
    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    const [t, i18n] = useTranslation("global");

    const [page, setPage] = useState(1); // New state to manage pagination
    const [isLoading, setIsLoading] = useState(false); // New state to manage loading status

    const [expandedIndexes, setExpandedIndexes] = useState({});

    const toggleExpand = (index) => {
        setExpandedIndexes((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const linkify = (text) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
        const escapedText = text
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');

        return escapedText
            .replace(urlPattern, '<a href="$1" target="_blank">$1</a>')
            .replace(emailPattern, '<a href="mailto:$1">$1</a>')
            .replace(/\n/g, '<br>');
    };

    const truncateText = (text, maxLength, isExpanded) => {
        if (isExpanded) {
            return text;
        }
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    const fetchItems = async (pageNumber, isFeed, pageSize = 10) => {
        console.log('Fetching page:', pageNumber);
        setIsLoading(true);
        try {

            if (pageNumber === 1) {
                setFeedListings([]);  // Clear the feed listings if fetching for feed
                setNewListings([]);   // Clear the news listings if fetching for news

                console.log('Why no do here?')
            }

            const response = await domainName.serverAPICall(
                domainName.debugAPI + (isFeed ? '/community/listAllCommunityPosts' : '/community/listAllCommunityNews'),
                'POST',
                JSON.stringify({
                    "Keyword": "",
                    "draw": 1,
                    "IsNormalUser": true,
                    "UserId": userIdForCommunity ? userIdForCommunity : null,
                    "PageNum": pageNumber,
                    "ResultNum": pageSize
                })
            );

            const data = await domainName.handleServerAPICallErrors(response);

            if (isFeed) {
                setFeedListings(prevListings => [...prevListings, ...data.Data]);
            } else {
                setNewListings(prevListings => [...prevListings, ...data.Data]);
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const handleObserver = entries => {
            const target = entries[0];
            if (target.isIntersecting && !isLoading) {
                setPage(prevPage => prevPage + 1);
            }
        };

        const observer = new IntersectionObserver(handleObserver, {
            root: null, // use the viewport
            rootMargin: '0px',
            threshold: 0.1 // Trigger when 10% of the target is visible
        });

        const sentinel = document.querySelector('#scroll-sentinel');
        if (sentinel) {
            observer.observe(sentinel);
        }

        return () => {
            if (sentinel) {
                observer.unobserve(sentinel);
            }
        };
    }, [isLoading]);



    async function submitComment(postDetail, commentText) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/community/userCreateComment",
                "POST",
                JSON.stringify({
                    "PostId": postDetail._id,
                    "UserId": userIdForCommunity,
                    "PostUserId": postDetail.author,
                    "CommentText": commentText
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((async responseJson => {
                //console.log("/community/userCreateComment responseJson: ", responseJson)

                if (responseJson.ReturnCode == 200) {
                    // getCommunityFeedPosts();
                    // getCommunityNewPosts();
                    // if (isFeed) {
                    //     await getCommunityFeedPosts()
                    // } else {
                    //     await getCommunityNewPosts()
                    // }
                    await fetchItems(1, isFeed);
                    console.log('AA 4')

                } else {
                    alert(t('error.Comment failed, please try it again later.'))
                }
            }))
            .catch((error) => {
                //console.log("/community/userCreateComment error: ", error)
            });
    }

    async function handleLikeAction(postDetails, index) {
        // Optimistically update the like count in the state
        const updatedFeedListings = [...feedListings];
        const post = updatedFeedListings[index];
        post.IsLike = !post.IsLike; // Toggle like status
        post.TotalLikes += post.IsLike ? 1 : -1; // Adjust the like count

        setFeedListings(updatedFeedListings);

        try {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/community/userLikePost",
                    "POST",
                    JSON.stringify({
                        "PostId": postDetails._id,
                        "UserId": userIdForCommunity,
                        "PostUserId": postDetails.author
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then(async (responseJson) => {
                    if (responseJson.ReturnCode !== 200) {
                        // If the API call fails, revert the optimistic update
                        post.IsLike = !post.IsLike; // Revert like status
                        post.TotalLikes += post.IsLike ? 1 : -1; // Adjust the like count
                        setFeedListings([...updatedFeedListings]);
                        alert(t('error.Like failed, please try it again later.'));
                    }
                })
                .catch((error) => {
                    // Revert the optimistic update in case of an error
                    post.IsLike = !post.IsLike; // Revert like status
                    post.TotalLikes += post.IsLike ? 1 : -1; // Adjust the like count
                    setFeedListings([...updatedFeedListings]);
                    console.error("Error liking the post:", error);
                });
        } catch (error) {
            // Revert the optimistic update in case of an error
            post.IsLike = !post.IsLike; // Revert like status
            post.TotalLikes += post.IsLike ? 1 : -1; // Adjust the like count
            setFeedListings([...updatedFeedListings]);
            console.error("Error liking the post:", error);
        }
    }




    async function checkAnonymousUserid(checkUserID) {
        //call api to check the user is still anonymous
        let returnBoolean;
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkIsAnonymouseUser",
                "POST",
                JSON.stringify({
                    "UserId": checkUserID
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                if (responseJson.ReturnCode == 200) {
                    returnBoolean = true
                } else {
                    returnBoolean = false
                }
            }))
            .catch((error) => {
                //console.log('/user/checkIsAnonymouseUser error here', error)
            });

        return returnBoolean
    }

    async function editCommunityNews(postsid, imagePassedIn, messageText) {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/community/editCommunityNews",
                "POST",
                JSON.stringify({
                    "PostsId": postsid,
                    "Type": "POST",
                    "Voting": [
                        {
                            "title": "option 1",
                            "userIdList": []
                        },
                        {
                            "title": "option 2",
                            "userIdList": []
                        },
                        {
                            "title": "option 3",
                            "userIdList": []
                        }
                    ],
                    "Title": messageText,
                    "Photos": imagePassedIn
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((async responseJson => {
                //console.log(`/community/editCommunityNews responseJson here`, responseJson)
                if (responseJson.ReturnCode == 200) {
                    setLoading(false)
                    setShowEditPostModal(false)
                    window.location.reload();
                    // if (isFeed) {
                    //     await getCommunityFeedPosts()
                    // } else {
                    //     await getCommunityNewPosts()
                    // }
                    // await fetchItems(1, isFeed);
                } else {
                    setLoading(false)
                    alert(`${t("error.Cannot edit")} ${isFeed ? t('community.Feed') : t('community.News')} ${("error.post. Please try it again later.")}`)
                }
            }))
            .catch((error) => {
                //console.log(`/community/editCommunityNews error here`, error)
            });

    }



    async function insertCommunityPost(imagePassedIn, messageText) {
        const api1 = `/community/insertCommunityPosts`;
        const api2 = `/community/insertCommunityNews`;

        await domainName
            .serverAPICall(
                domainName.debugAPI + `${isFeed ? api1 : api2}`,
                "POST",
                JSON.stringify({
                    "UserId": userIdForCommunity,
                    "Type": "POST",
                    "Voting": [
                        {
                            "title": "option 1",
                            "userIdList": []
                        },
                        {
                            "title": "option 2",
                            "userIdList": []
                        },
                        {
                            "title": "option 3",
                            "userIdList": []
                        }
                    ],
                    "Title": messageText,
                    "Photos": imagePassedIn ? [imagePassedIn] : []
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((async responseJson => {
                //console.log(`${isFeed ? api1 : api2} responseJson here`, responseJson)
                if (responseJson.ReturnCode == 200) {
                    setLoading(false)
                    window.location.reload();
                    // if (isFeed) {
                    //     await getCommunityFeedPosts()
                    // } else {
                    //     await getCommunityNewPosts()
                    // }
                    // await fetchItems(1, isFeed);
                } else {
                    setLoading(false)
                    alert(`${t("error.Cannot upload")} ${isFeed ? t('community.Feed') : t('community.News')} ${t("error.post. Please try it again later.")}`)
                }

                setShowCreatePostModal(false)
            }))
            .catch((error) => {
                //console.log(`${isFeed ? api1 : api2} error here`, error)
            });

    }

    async function convertImageTo64ForEdit(postid, imagePassed, messagePAss) {

        let result64Image;
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64Data = reader.result;
            //console.log("base64Data", base64Data);

            editCommunityNews(postid, [base64Data], messagePAss)
        };

        reader.onerror = (error) => {
            alert(`${t("Error reading image file :")} ${error}`);
        };

        reader.readAsDataURL(imagePassed);


        return result64Image
    }

    const handleResize = () => {
        const width = window.innerWidth;
        if (width < 768) {
            setView('mobile');
        } else if (width >= 768 && width < 1500) {
            setView('laptop');
        } else if (width >= 1500 && width <= 3000) {
            setView('monitor');
        } else {
            setView('default');
        }
    };


    async function convertImageTo64(imagePassed, messagePAss) {

        let result64Image;
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64Data = reader.result;
            //console.log("base64Data", base64Data);

            insertCommunityPost(base64Data, messagePAss)
        };

        reader.onerror = (error) => {
            alert(`${t("error.Error reading image file :")} ${error}`);
        };

        reader.readAsDataURL(imagePassed);


        return result64Image
    }


    async function getCommunityNewPosts() {
        const currentDate = new Date(); // Get the current date
        currentDate.setDate(currentDate.getDate() - 30); // Subtract 30 days from the current date to get time1
        const time1 = currentDate.toISOString(); // Convert time1 to ISO string format

        currentDate.setDate(currentDate.getDate() + 31); // Add 31 days from the current date (1 days after the original current date) to get time2
        const time2 = currentDate.toISOString(); // Convert time2 to ISO string format

        setTimeout(async () => {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/community/listAllCommunityNews",
                    "POST",
                    JSON.stringify({
                        "DateRange1": time1,
                        "DateRange2": time2,
                        "Keyword": "",
                        "start": 1,
                        "length": 100,
                        "draw": 1,
                        "IsNormalUser": true,
                        "UserId": userIdForCommunity ? userIdForCommunity : null
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    setNewListings(responseJson.Data)
                }))
                .catch((error) => {
                    //console.log('/community/listAllCommunityNews error here', error)
                });
        }, 1000); // Delay by one second (1000 milliseconds)
    }

    async function getCommunityFeedPosts(user_id) {

        const currentDate = new Date(); // Get the current date

        currentDate.setDate(currentDate.getDate() - 30); // Subtract 30 days from the current date to get time1
        const time1 = currentDate.toISOString(); // Convert time1 to ISO string format

        currentDate.setDate(currentDate.getDate() + 31); // Add 31 days from the current date (1 days after the original current date) to get time2
        const time2 = currentDate.toISOString(); // Convert time2 to ISO string format

        setTimeout(async () => {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/community/listAllCommunityPosts",
                    "POST",
                    JSON.stringify({
                        "DateRange1": time1,
                        "DateRange2": time2,
                        "Keyword": "",
                        "start": 1,
                        "length": 100,
                        "draw": 1,
                        "IsNormalUser": true,
                        "UserId": user_id ? user_id : userIdForCommunity ? userIdForCommunity : null
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    setFeedListings(responseJson.Data)
                }))
                .catch((error) => {
                    //console.log('/community/listAllCommunityPosts error here', error)
                });
        }, 1000); // Delay by one second (1000 milliseconds)

    }


    async function getCommunityPosts(user_id) {

        const currentDate = new Date(); // Get the current date

        currentDate.setDate(currentDate.getDate() - 30); // Subtract 30 days from the current date to get time1
        const time1 = currentDate.toISOString(); // Convert time1 to ISO string format

        currentDate.setDate(currentDate.getDate() + 31); // Add 31 days from the current date (1 days after the original current date) to get time2
        const time2 = currentDate.toISOString(); // Convert time2 to ISO string format

        let _api = isFeed ? "listAllCommunityPosts" : "listAllCommunityNews"

        setTimeout(async () => {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/community/" + _api,
                    "POST",
                    JSON.stringify({
                        "DateRange1": time1,
                        "DateRange2": time2,
                        "Keyword": "",
                        "start": 1,
                        "length": 100,
                        "draw": 1,
                        "IsNormalUser": true,
                        "UserId": user_id ? user_id : userIdForCommunity ? userIdForCommunity : null
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    setFeedListings(responseJson.Data)
                }))
                .catch((error) => {
                    //console.log('/community/listAllCommunityPosts error here', error)
                });
        }, 1000); // Delay by one second (1000 milliseconds)
    }


    function RenderCommunityPostListing(itemListings) {

        const [commentLabelVisibility, setCommentLabelVisibility] = useState({});
        // const [commentValue, setCommentValue] = useState('')

        // comment listing
        const [commentListings, setCommentListings] = useState({});

        async function getCommentByPostId(postid, index) {
            // if (commentLabelVisibility[index]) { // Check if the comment label is visible
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/community/listAllCommentByPostId",
                    "POST",
                    JSON.stringify({
                        "PostId": postid
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    //console.log("/community/listAllCommentByPostId responseJson: ", responseJson)
                    // Update the commentListings state with comments for the respective post index
                    console.log(responseJson.Totalcomments)
                    setIsCommentHovered(responseJson.Totalcomments)
                    setCommentListings((prevCommentListings) => ({
                        ...prevCommentListings,
                        [index]: responseJson.Data
                    }));

                }))
                .catch((error) => {
                    //console.log("/community/listAllCommentByPostId error: ", error)
                });
            // }
        }


        const toggleCommentLabel = (index) => {
            setCommentLabelVisibility((prevVisibility) => ({
                ...prevVisibility,
                [index]: !prevVisibility[index],
            }));
        };

        return (
            <div className=''>
                {itemListings.length > 0 && itemListings.map((e, index) => (
                    <Row
                        key={index}
                        style={{
                            //cursor: 'pointer' 
                            borderWidth: 0,
                            borderBottomWidth: '5px',
                            borderColor: COLORS.lightGrayAccount,
                            borderStyle: 'solid'
                        }} >

                        <Container className='p-5 pt-4'>
                            <Row>
                                <Col xs={3} md={1}>
                                    <img
                                        src={e.UserImageDisplay}
                                        alt="Image"
                                        style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover' }}
                                    />
                                </Col>

                                <Col xs={9} md={11}>
                                    <div style={{ fontWeight: 'bold' }}>{e.UserName}</div>
                                    <div style={{ color: COLORS.gray }}>{e.CreatedAtDisplay}</div>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='mt-3'>
                                    <div style={{ overflowWrap: 'break-word' }}>
                                        <div style={{ overflowWrap: 'break-word' }}>
                                            {e.title && (
                                                <div className={expandedIndexes[index] ? 'expanded-text' : 'truncated-text'} dangerouslySetInnerHTML={{ __html: linkify(truncateText(e.title, 100, expandedIndexes[index])) }}></div>
                                            )}
                                            {e.title && e.title.length > 100 && !expandedIndexes[index] && (
                                                <a className="seeMore" onClick={() => toggleExpand(index)}>
                                                    See more
                                                </a>
                                            )}
                                            {e.title && expandedIndexes[index] && (
                                                <a className="seeMore" onClick={() => toggleExpand(index)}>
                                                    See less
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {
                                e.ListingImageDisplay.length > 0 && e.ListingImageDisplay.map((image, index) => (
                                    <Row key={index} style={{ marginTop: '2%', marginBottom: '2%' }}>
                                        <Col xs={12} lg={10}>
                                            {
                                                view == "mobile" &&
                                                <img
                                                    src={image}
                                                    alt="Image"
                                                    className="img-fluid"
                                                    style={{
                                                        width: '100%',
                                                        padding: 0,
                                                        margin: 0,
                                                        objectFit: 'contain',
                                                        height: 'auto',
                                                        borderRadius: '10px',
                                                        // maxHeight: '100%',
                                                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                                    }}
                                                />
                                            }
                                            {
                                                view == "laptop" &&
                                                <img
                                                    src={image}
                                                    alt="Image"
                                                    className="img-fluid"
                                                    style={{
                                                        width: '100%',
                                                        padding: 0,
                                                        margin: 0,
                                                        objectFit: 'contain',
                                                        // height: 'auto',
                                                        height: '300px',
                                                        borderRadius: '10px',
                                                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                                        // maxHeight: '120px',
                                                    }}
                                                />
                                            }
                                            {
                                                view == "monitor" &&
                                                <img
                                                    src={image}
                                                    alt="Image"
                                                    className="img-fluid"
                                                    style={{

                                                        width: 'auto',
                                                        padding: 0,
                                                        margin: 0,
                                                        objectFit: 'contain',
                                                        // height: 'auto',
                                                        height: '400px',
                                                        borderRadius: '10px',
                                                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                                        // maxHeight: '120px',
                                                    }}
                                                />
                                            }
                                            {
                                                view == "default" &&
                                                <img
                                                    src={image}
                                                    alt="Image"
                                                    className="img-fluid"
                                                    style={{
                                                        width: '100%',
                                                        padding: 0,
                                                        margin: 0,

                                                        objectFit: 'contain',
                                                        height: 'auto',
                                                        maxHeight: '100%',
                                                        borderRadius: '10px',
                                                        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                                    }}
                                                />
                                            }

                                        </Col>
                                    </Row>
                                ))



                            }

                            <Row className='mt-2'>
                                <Col
                                    xs={3}
                                    lg={2}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: userIdValid ? 'pointer' : 'not-allowed',
                                        borderRadius: '5px',
                                        padding: '5px',
                                        backgroundColor: isLikeHovered ? 'transparent' : 'transparent',
                                    }}
                                    onClick={() => {
                                        if (userIdValid) {
                                            handleLikeAction(e, index);
                                        } else {
                                            alert(t('error.Kindly sign in first.'));
                                        }

                                    }}

                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div>
                                            <Image src={require(e.IsLike ? '../../img/icons/liked.png' : '../../img/icons/like.png')} fluid
                                                style={{
                                                    width: '20px',
                                                    height: '20px'
                                                }}
                                                onMouseEnter={() => setIsLikeHovered(true)}
                                                onMouseLeave={() => setIsLikeHovered(false)}
                                            />
                                        </div>
                                        <div style={{
                                            marginLeft: '3%', marginTop: '2%'
                                        }}>
                                            <span style={{
                                                color: COLORS.gray
                                            }}>
                                                {e.TotalLikes > 0 && e.TotalLikes}

                                                {/* {e.TotalLikes} */}
                                            </span>
                                        </div>
                                    </div>
                                </Col>


                                <Col
                                    xs={3}
                                    lg={2}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: userIdValid ? 'pointer' : 'not-allowed',
                                        borderRadius: '5px',
                                        padding: '5px',
                                        backgroundColor: isCommentHovered ? '#1A73E8' : 'transparent',
                                    }}
                                    onClick={() => {
                                        if (userIdValid) {
                                            console.log('clicked comment')
                                            toggleCommentLabel(index)
                                            getCommentByPostId(e._id, index)
                                        } else {
                                            alert(t('error.Kindly sign in first.'));
                                        }
                                    }}

                                    onMouseEnter={() => { setIsCommentHovered() }}
                                    onMouseLeave={() => { setIsCommentHovered() }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div>
                                            <Image
                                                src={require('../../img/icons/comments.png')}
                                                fluid
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        </div>
                                        <div style={{ marginLeft: '3%', marginTop: '2%' }}>
                                            <span style={{ color: COLORS.gray }}>{e.Totalcomments > 0 && e.Totalcomments}</span>
                                        </div>
                                    </div>
                                </Col>

                                {
                                    e.author == userIdForCommunity &&
                                    <Col xs={3} lg={2}>
                                        {/* Option */}
                                        <div className="dropdown">
                                            {/* Category dropdown */}
                                            <div
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    paddingTop: '2%',
                                                }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <img
                                                        src={require('../../img/icons/more.png')}
                                                        style={{ width: '20px', height: '20px', alignSelf: 'center', marginRight: '5%' }}
                                                        alt="More icon"
                                                        fluid
                                                    />
                                                </div>
                                            </div>

                                            <ul className="dropdown-menu" style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1000 }}>
                                                <li>
                                                    <a
                                                        className={`dropdown-item`}
                                                        href="#"
                                                        onClick={() => {
                                                            if (userIdValid) {
                                                                setEditItem(e);
                                                                setShowEditPostModal(true);
                                                            } else {
                                                                alert(t('error.Kindly sign in first.'));
                                                            }
                                                        }}
                                                    >
                                                        Edit
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className={`dropdown-item`}
                                                        href="#"
                                                        onClick={() => {
                                                            if (userIdValid) {
                                                                setDeleteItem(e);
                                                                setShowDeletePostModal(true);
                                                            } else {
                                                                alert(t('error.Kindly sign in first.'));
                                                            }
                                                        }}
                                                    >
                                                        Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </Container>


                        {/* Add comment label */}
                        {commentLabelVisibility[index] && (
                            <Row className='mt-2'>
                                <Col xs={3} lg={1} >
                                    <div></div>
                                </Col>
                                <Col xs={9} lg={9} >
                                    <textarea
                                        id="commentInput"
                                        name="commentInput"
                                        style={{
                                            height: '120px',
                                            borderColor: COLORS.lightGray1,
                                            borderWidth: 1,
                                            borderRadius: 5,
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            padding: '5px',
                                            fontSize: '16px',
                                            backgroundColor: 'transparent',
                                            // marginTop: '5%',
                                            height: '70px',
                                            resize: 'none', // Disable textarea resizing
                                        }}
                                        placeholder={t("community.Leave your comment here...")}
                                        onChange={(onC) => {
                                            // setCommentValue(onC.target.value)
                                        }}
                                        onKeyDown={(onK) => {
                                            if (onK.key === 'Enter') {
                                                const commentValue = document.querySelector('textarea[name="commentInput"]').value;
                                                submitComment(e, commentValue)
                                                // setCommentValue('')
                                                document.querySelector('textarea[name="commentInput"]').value = ""

                                                toggleCommentLabel(index)

                                            }
                                        }}
                                    // value={commentValue}
                                    ></textarea>
                                </Col>

                            </Row>
                        )}

                        {/* Display comment label */}
                        {
                            commentListings[index] && commentLabelVisibility[index] && commentListings[index].map((comments, commentIndex) => (
                                <Row className='mt-2' key={commentIndex}>
                                    <Col xs={3} lg={1} >
                                        <img
                                            // src={e.ReviewerImageDisplay}
                                            src={comments.author.ImageDisplay}
                                            alt="Image"
                                            className="img-fluid"
                                            style={{ width: '100%', height: 'auto', maxWidth: '90px', maxHeight: '80px', borderRadius: '50%' }}
                                        />
                                    </Col>
                                    <Col xs={9} lg={9}>
                                        <Container>
                                            <div
                                                style={{
                                                    borderColor: COLORS.lightGray1,
                                                    borderStyle: 'solid',
                                                    borderWidth: 1,
                                                    borderRadius: 5,
                                                    boxSizing: 'border-box',
                                                    padding: '5px',
                                                    fontSize: '16px',
                                                    backgroundColor: 'transparent',
                                                    overflow: 'auto',
                                                }}
                                            >

                                                <div style={{ display: isWebsite ? 'flex' : '', flexDirection: isWebsite ? 'row' : '', justifyContent: isWebsite ? 'space-between' : '' }}>

                                                    <div style={{ fontWeight: 'bold' }}>{comments.author.Name}</div>
                                                    {!isWebsite && (<br></br>)}
                                                    <div>{comments.DisplayCreatedAt}</div>
                                                </div>
                                                <textarea
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        resize: 'none',
                                                        border: 'none',
                                                        outline: 'none',
                                                        backgroundColor: 'transparent',
                                                        fontSize: '16px',
                                                        color: 'black',
                                                    }}
                                                    value={comments.comment}
                                                    readOnly
                                                />
                                            </div>
                                        </Container>
                                    </Col>
                                </Row>
                            ))

                        }

                    </Row>
                ))}
            </div>
        )
    }

    function renderTitleLabel() {
        return (
            <Row className="tab" >
                <Col style={{ display: 'flex', flexDirection: 'row', borderWidth: 0, borderColor: COLORS.lightGray1, borderStyle: 'solid', borderBottomWidth: '1px', marginLeft: '12px', marginRight: '12px', marginTop: '25px', paddingLeft: '25px' }}>
                    <div style={{ paddingBottom: '5px', borderWidth: 0, marginRight: '5%', cursor: 'pointer', borderBottomWidth: isFeed ? '3px' : 0, borderColor: COLORS.orange, borderStyle: 'solid' }}
                        onClick={() => {
                            setIsFeed(true)
                        }}
                    >
                        <span style={{ color: isFeed ? COLORS.black : COLORS.gray, fontWeight: 'bold' }}>{t('community.Feed')}</span>
                    </div>

                    <div style={{ borderWidth: 0, cursor: 'pointer', borderBottomWidth: isFeed ? 0 : '3px', borderColor: COLORS.orange, borderStyle: 'solid' }}
                        onClick={() => {
                            setIsFeed(false)
                        }}>
                        <span style={{ color: isFeed ? COLORS.gray : COLORS.black, fontWeight: 'bold' }}>{t('community.News')}</span>
                    </div>
                </Col>
            </Row>
        )
    }


    function renderFeedNewsListingSection() {
        return (
            <Col md={12} lg={9} className='community-container'>
                <div className='box-container'>
                    {renderTitleLabel()}

                    <div className='tab-content'>
                        {((view === "mobile") && (renderCreatePostSection()))}
                        {
                            isFeed ?
                                RenderCommunityPostListing(feedListings)
                                :
                                RenderCommunityPostListing(newListings)
                        }
                    </div>

                </div>
            </Col>
        )
    }


    function renderCreatePostSection() {
        return (
            <>
                {((view === "laptop" || view === "monitor" || view === "default") && (
                    <Col className="box-container" md={12} lg={3}>
                        <Container className="pt-4">
                            <Row>
                                <Col style={{ fontWeight: 'bold' }}>{t('community.Post')}</Col>
                            </Row>

                            <Row >
                                <Col className='mt-3' >
                                    <div className='p-4 ' style={{ backgroundColor: COLORS.lightGray4, borderRadius: '5px' }}>
                                        <div style={{ fontWeight: 'bold' }}>{t('community.Share your thoughts')}</div>
                                        <div>{t('community.Leave a message by clicking the button below and let us know your thoughts!')}</div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <button
                                        className="btn-white btn"
                                        style={{ borderColor: COLORS.orange, width: '100%' }}
                                        onClick={() => {
                                            if (userIdValid) {
                                                setShowCreatePostModal(true);
                                            } else {
                                                alert(t('error.Kindly sign in first.'));
                                            }
                                        }}
                                    >
                                        <span style={{ color: COLORS.orange }}>{t('community.+ Create')} {isFeed ? t('community.Feed') : t('community.News')} {t('community.Post')}</span>
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                ))}

                {view === "mobile" && (
                    <Container className="p-4 pt-2" style={{
                        //cursor: 'pointer' 
                        borderWidth: 0,
                        borderBottomWidth: '5px',
                        borderColor: COLORS.lightGrayAccount,
                        borderStyle: 'solid'
                    }}>
                        <Row >
                            <Col className='mt-3' >
                                <div className='p-4 ' style={{ backgroundColor: COLORS.lightGray4, borderRadius: '5px' }}>
                                    <div style={{ fontWeight: 'bold' }}>{t('community.Share your thoughts')}</div>
                                    <div>{t('community.Leave a message by clicking the button below and let us know your thoughts!')}</div>
                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                <button
                                    className="btn-white btn"
                                    style={{ borderColor: COLORS.orange, width: '100%' }}
                                    onClick={() => {
                                        if (userIdValid) {
                                            setShowCreatePostModal(true);
                                        } else {
                                            alert(t('error.Kindly sign in first.'));
                                        }
                                    }}
                                >
                                    <span style={{ color: COLORS.orange }}>{t('community.+ Create')} {isFeed ? t('community.Feed') : t('community.News')} {t('community.Post')}</span>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                )}

            </>
        );
    }

    function renderPostDescription(title) {
        if (title != null) {
            const descriptionLines = decodeURIComponent(encodeURIComponent(title)).split('\n');

            // Function to convert URLs in text to clickable links
            const convertUrlsToLinks = (text) => {
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                return text.replace(urlRegex, (url) => {
                    return `<a href="${url}" target="_blank">${url}</a>`;
                });
            };

            return (
                <div>
                    {
                        descriptionLines.map((line, index) => (
                            // Render HTML using dangerouslySetInnerHTML to allow HTML tags
                            <p key={index} dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(line) }} />
                        ))
                    }
                </div>
            );
        }
    }




    function DeletePostModal() {
        return (
            <Modal show={showDeletePostModal} onHide={() => setShowDeletePostModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('community.Delete Post')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('community.Are you sure you want to delete this post?')}</Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeletePostModal(false)}>
                        {t('community.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        await domainName
                            .serverAPICall(
                                domainName.debugAPI + "/community/adminUpdateCommunityEnabled",
                                "POST",
                                JSON.stringify({
                                    "PostsId": deleteItem._id,
                                    "Enabled": "false"
                                })
                            )
                            .then(domainName.handleServerAPICallErrors)
                            .then((async responseJson => {
                                if (responseJson.ReturnCode == 200) {
                                    // if (isFeed) {
                                    //     await fetchItems(1, isFeed);
                                    //     // await getCommunityFeedPosts()
                                    // } else {
                                    //     await fetchItems(1, isFeed);
                                    //     // await getCommunityNewPosts()
                                    // }
                                    window.location.reload();
                                    // await fetchItems(1, isFeed);
                                } else {
                                    alert(t("error.Failed to delete this post, please try it again later."))
                                }

                                setShowDeletePostModal(false)
                            }))
                            .catch((error) => {
                                //console.log('/community/listAllCommunityNews error here', error)
                            });
                    }}>
                        {t('community.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function EditPostModal() {

        const [messageValue, setMessageValue] = useState(editItem?.title)
        const [selectedPicture, setSelectedPicture] = useState(null);
        const [previewImage, setPreviewImage] = useState(editItem?.ListingImageDisplay[0]);
        const [listingImage, setListingImage] = useState(editItem?.listingImage)


        const handlePictureChange = (e) => {
            const file = e.target.files[0];
            if (file && file.type.startsWith('image/')) {
                setSelectedPicture(file);
                setPreviewImage(URL.createObjectURL(file));
            } else {
                setSelectedPicture(null);
                setPreviewImage(null);
            }
        };

        return (
            <Modal show={showEditPostModal} onHide={() => {
                setShowEditPostModal(false)
                setSelectedPicture(null);
                setPreviewImage(null);
                setMessageValue('');
            }} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('community.Edit')} {isFeed ? t('community.Feed') : t('community.News')} {t('community.Post')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        id="messageInput"
                        name="messageInput"
                        style={{
                            height: '120px',
                            borderColor: COLORS.lightGray1,
                            borderWidth: 1,
                            borderRadius: 5,
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '5px',
                            fontSize: '16px',
                            // marginTop: '5%',
                            resize: 'none', // Disable textarea resizing
                        }}
                        placeholder={t("community.What's on your mind...")}
                        onChange={(e) => { setMessageValue(e.target.value) }}
                        value={messageValue}
                    ></textarea>
                    <div >
                        <label htmlFor="imageUpload" className="custom-file-upload" style={{ cursor: 'pointer' }}>
                            <span style={{ color: COLORS.orange }}>{t('community.Choose an image')}</span>
                        </label>
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={handlePictureChange}
                            className="mt-3"
                            style={{ display: 'none' }}
                        />
                    </div>
                    {previewImage && (
                        <div style={{ position: 'relative', marginTop: '3%', width: '150px' }}>
                            <img src={previewImage} width={150} height={150} style={{ borderRadius: '10px' }} />
                            <div
                                onClick={() => {
                                    setSelectedPicture(null);
                                    setPreviewImage(null);
                                }}
                                style={{ position: 'absolute', top: '2px', right: '2px', zIndex: '1' }}
                            >
                                <img
                                    src={require('../../img/icons/close.png')}
                                    style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }}
                                    alt="Close"
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowEditPostModal(false)
                        setSelectedPicture(null);
                        setPreviewImage(null);
                        setMessageValue('');

                    }}>
                        {t('community.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        setLoading(true)
                        const messageInput = document.querySelector('textarea[name="messageInput"]').value;
                        //console.log("messageInput", messageInput)
                        if (selectedPicture) {
                            await convertImageTo64ForEdit(editItem._id, selectedPicture, messageInput)
                        } else {
                            await editCommunityNews(editItem._id, previewImage ? listingImage : null, messageInput)
                        }

                    }} disabled={loading}>
                        {loading ? t('community.Loading...') : t('community.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function CreatePostModal() {

        const [messageValue, setMessageValue] = useState('')
        const [selectedPicture, setSelectedPicture] = useState(null);
        const [previewImage, setPreviewImage] = useState(null);


        const handlePictureChange = (e) => {
            const file = e.target.files[0];
            if (file && file.type.startsWith('image/')) {
                setSelectedPicture(file);
                setPreviewImage(URL.createObjectURL(file));
            } else {
                setSelectedPicture(null);
                setPreviewImage(null);
            }
        };

        return (
            <Modal show={showCreatePostModal} onHide={() => {
                setShowCreatePostModal(false)
                setSelectedPicture(null);
                setPreviewImage(null);
            }} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('community.Create')} {isFeed ? t('community.Feed') : t('community.News')} {t('community.Post')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        id="messageInput"
                        name="messageInput"
                        style={{
                            height: '120px',
                            borderColor: COLORS.lightGray1,
                            borderWidth: 1,
                            borderRadius: 5,
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '5px',
                            fontSize: '16px',
                            // marginTop: '5%',
                            resize: 'none', // Disable textarea resizing
                        }}
                        value={messageValue}
                        // value={messageValue && decodeURIComponent(messageValue)}
                        onChange={(e) => { setMessageValue(e.target.value) }}
                    ></textarea>
                    <div >
                        <label htmlFor="imageUpload" className="custom-file-upload" style={{ cursor: 'pointer' }}>
                            <span style={{ color: COLORS.orange }}>{t('community.Choose an image')}</span>
                        </label>
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={handlePictureChange}
                            className="mt-3"
                            style={{ display: 'none' }}
                        />
                    </div>
                    {previewImage && (
                        <div style={{ position: 'relative', marginTop: '3%', width: '150px' }}>
                            <img src={previewImage} width={150} height={150} style={{ borderRadius: '10px' }} />
                            <div
                                onClick={() => {
                                    setSelectedPicture(null);
                                    setPreviewImage(null);
                                }}
                                style={{ position: 'absolute', top: '2px', right: '2px', zIndex: '1' }}
                            >
                                <img
                                    src={require('../../img/icons/close.png')}
                                    style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }}
                                    alt="Close"
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowCreatePostModal(false)
                        setSelectedPicture(null);
                        setPreviewImage(null);
                    }}>
                        {t('community.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        setLoading(true)

                        const messageInput = document.querySelector('textarea[name="messageInput"]').value;

                        // console.log("messageInput", messageInput)

                        if (messageInput == "" | messageInput.trim().length === 0) {
                            alert(t('error.Text content field cannot be empty .'))
                        } else {
                            //console.log("messageInput", messageInput)
                            if (selectedPicture) {
                                // console.log("aaa")
                                await convertImageTo64(selectedPicture, messageInput)
                            } else {
                                // console.log("bbb")
                                await insertCommunityPost(undefined, messageInput)
                            }
                        }

                    }} disabled={loading}>
                        {loading ? t('community.Loading...') : t('community.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const fetchData = async (pageNumber) => {
        handleResize();
        setIsIOS(!!userAgent.match(/iPad|iPhone|iPod/));
        setIsAndroid(!!userAgent.match(/Android/));

        const searchParams = new URLSearchParams(window.location.search);
        const encryptedID = searchParams.get('uid');
        const userid = searchParams.get('userid');
        const timeid = searchParams.get('tid');
        const isFromApp = searchParams.get('isTarget') ? searchParams.get('isTarget') === 'app' : false;
        setIsFromAppUser(isFromApp);

        let userIdToCheck = UserId;

        if (encryptedID) {
            const key = process.env.REACT_APP_COMMUNITY_APP_KEY;
            const ciphertext = SHA256(userid + key + "Adviser2u" + timeid).toString();

            if (ciphertext === encryptedID.toString()) {
                userIdToCheck = userid;
                setUserIdForCommunity(userid);
            }
        }

        const trueFalse = await checkAnonymousUserid(userIdToCheck);
        setUserIdValid(trueFalse);

        setUserIdForCommunity(userIdToCheck);
        await fetchItems(pageNumber, isFeed);

        let queryParam = queryString.parse(search);
        let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue');
        setPathLang(_lang);
        i18n.changeLanguage(_lang);

        console.log(`AA ${pageNumber === 1 ? '2' : '3'}`);
    };

    useEffect(() => {
        setPage(1);
        setFeedListings([]);
        setNewListings([]);

        // Directly call fetchData for page 1 when isFeed changes
        fetchData(1);
    }, [isFeed]);

    useEffect(() => {
        if (page !== 1) {
            fetchData(page);
        }
    }, [page]);


    return (
        <div>
            {/* i want to hide the navigation bar if  is true */}
            {/* {!isIOS && !isAndroid && <Navigation />} */}

            {!isFromAppUser && <Navigation />}

            <div className="greybk-div pt-lg-4" >
                <Container className="width95" >
                    <Row>
                        {renderFeedNewsListingSection()}

                        {((view === "laptop" || view === "monitor" || view === "default") && (renderCreatePostSection()))}
                    </Row>

                    <DeletePostModal />
                    <EditPostModal />
                    <CreatePostModal />
                </Container>
            </div>
            <div id="scroll-sentinel" style={{ height: '1px' }}></div>

        </div>
    )
}

export default Community